import React, { useState, useEffect } from "react";
import axios from "axios";
import { Table, Pagination, Modal, Button, Spinner } from "react-bootstrap";

const Offering = () => {
  const [offeringData, setOfferingData] = useState([]);
  const [offeringCode, setOfferingCode] = useState("");
  const [offeringDescription, setOfferingDescription] = useState("");
  const [offeringTypeOptions, setOfferingTypeOptions] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage] = useState(10);
  const [offeringType, setOfferingType] = useState("");
  const [checkUpdate, setCheckUpdate] = useState(null);
  const [offeringName, setOfferingName] = useState("");
  const [active, setActive] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false); // Loading state

  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = offeringData?.slice(indexOfFirstRow, indexOfLastRow);
  const totalPages = Math.ceil(offeringData?.length / rowsPerPage);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => {
    setOfferingType("");
    setOfferingCode("");
    setActive(false);
    setCheckUpdate(null);
    setOfferingName("");
    setOfferingDescription("");
    setShowModal(false);
  };

  useEffect(() => {
    setLoading(true); // Start loading
    axios
      .get(`${process.env.REACT_APP_API_URL}offering`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        setOfferingData(response.data);
        setLoading(false); // Stop loading
      })
      .catch((error) => {
        setLoading(false); // Stop loading
        alert("Something went wrong while fetching offerings.");
      });

    axios
      .get(`${process.env.REACT_APP_API_URL}offering_type`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        setOfferingTypeOptions(response.data);
      })
      .catch((error) => {
        alert("Something went wrong while fetching offering types.");
      });
  }, [showModal]);

  const handleAddOffering = () => {
    if (!offeringType || !offeringName || !offeringDescription) {
      alert("Please fill all the fields.");
      return;
    }

    const formData = {
      offering_code: offeringCode,
      offering_name: offeringName,
      offering_description: offeringDescription,
      offering_type_id: offeringType,
      active: active ? 1 : 0,
    };

    setLoading(true); // Start loading
    if (checkUpdate !== null) {
      axios
        .post(
          `${process.env.REACT_APP_API_URL}offering/${checkUpdate}`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        )
        .then(() => {
          setOfferingData(
            offeringData.map((item) =>
              item.offering_id === checkUpdate ? { ...item, ...formData } : item
            )
          );
          handleCloseModal();
          setLoading(false); // Stop loading
        })
        .catch(() => {
          setLoading(false); // Stop loading
          alert("An error occurred while updating the offering.");
        });
    } else {
      axios
        .post(`${process.env.REACT_APP_API_URL}offering`, formData, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((response) => {
          setOfferingData([...offeringData, response.data]);
          handleCloseModal();
          setLoading(false); // Stop loading
        })
        .catch(() => {
          setLoading(false); // Stop loading
          alert("An error occurred while creating the offering.");
        });
    }
  };

  const handleUpdateOffering = (offeringId) => {
    handleShowModal();
    setCheckUpdate(offeringId);

    setLoading(true); // Start loading
    axios
      .get(`${process.env.REACT_APP_API_URL}offering/${offeringId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        const data = response.data[0];
        setOfferingCode(data.offering_code);
        setOfferingType(data.offering_type_id);
        setOfferingName(data.offering_name);
        setOfferingDescription(data.offering_description);
        setActive(data.active);
        setLoading(false); // Stop loading
      })
      .catch(() => {
        setLoading(false); // Stop loading
        alert("Something went wrong while fetching offering details.");
      });
  };

  return (
    <div>
      <div className="d-flex justify-content-between align-items-center mb-3">
        <h1>Offering</h1>
        <button
          className="btn btn-primary"
          style={{ marginRight: "1rem" }}
          onClick={handleShowModal}
        >
          ADD
        </button>
      </div>
      {loading ? (
        <div className="text-center">
          <Spinner animation="border text-primary" />
        </div>
      ) : (
        <div>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>SNo.</th>
                <th>Offering Code</th>
                <th>Offering Name</th>
                <th>Offering Description</th>
                <th>Type</th>
                <th>Active</th>
              </tr>
            </thead>
            <tbody>
              {currentRows?.map((offering, index) => (
                <tr key={offering?.offering_id}>
                  <td>{indexOfFirstRow + index + 1}</td>
                  <td
                    onClick={() => handleUpdateOffering(offering?.offering_id)}
                    style={{
                      cursor: "pointer",
                      color: "blue",
                      fontWeight: "bold",
                      textDecoration: "underline",
                    }}
                  >
                    {offering?.offering_code}
                  </td>
                  <td>{offering?.offering_name}</td>
                  <td>{offering?.offering_description}</td>
                  <td>{offering?.type}</td>
                  <td>{offering?.active ? "Yes" : "No"}</td>
                </tr>
              ))}
            </tbody>
          </Table>

          {/* Pagination */}
          <div className="d-flex justify-content-center">
            <Pagination>
              <Pagination.Prev
                onClick={() => currentPage > 1 && paginate(currentPage - 1)}
                disabled={currentPage === 1}
              >
                Previous
              </Pagination.Prev>
              {Array(totalPages)
                ?.fill()
                ?.map((_, index) => (
                  <Pagination.Item
                    key={index}
                    active={index + 1 === currentPage}
                    onClick={() => paginate(index + 1)}
                  >
                    {index + 1}
                  </Pagination.Item>
                ))}
              <Pagination.Next
                onClick={() =>
                  currentPage < totalPages && paginate(currentPage + 1)
                }
                disabled={currentPage === totalPages}
              >
                Next
              </Pagination.Next>
            </Pagination>
          </div>
        </div>
      )}

      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>{checkUpdate ? "Edit" : "Add"} Offering</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
            <div className="form-group">
              <label htmlFor="offeringCode">Offering Code</label>
              <input
                type="text"
                className="form-control"
                onChange={(e) => setOfferingCode(e.target.value)}
                value={offeringCode}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="offeringType">Offering Type</label>
              <select
                className="form-control"
                onChange={(e) => setOfferingType(e.target.value)}
                value={offeringType}
                required
              >
                <option value="">Select Offering Type</option>
                {offeringTypeOptions?.map((option) => (
                  <option
                    key={option.offering_type_id}
                    value={option.offering_type_id}
                  >
                    {option.type}
                  </option>
                ))}
              </select>
            </div>
            <div className="form-group">
              <label htmlFor="offeringName">Offering Name</label>
              <input
                type="text"
                className="form-control"
                onChange={(e) => setOfferingName(e.target.value)}
                value={offeringName}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="offeringDescription">Offering Description</label>
              <input
                type="text"
                className="form-control"
                onChange={(e) => setOfferingDescription(e.target.value)}
                value={offeringDescription}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="active">Active</label>
              <input
                type="checkbox"
                onChange={(e) => setActive(e.target.checked)}
                checked={active}
              />
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
          <Button variant="primary" onClick={handleAddOffering}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Offering;
