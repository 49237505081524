import React, { useEffect, useState } from "react";
import axios from "axios";
import { Modal, Button } from "react-bootstrap";

const AirportManager = () => {
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [airports, setAirports] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedState, setSelectedState] = useState(null);

  const [airportName, setAirportName] = useState("");
  const [airportIataCode, setAirportIataCode] = useState("");
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  const [active, setActive] = useState(true);
  const [priority, setPriority] = useState(10);
  const [selectedCity, setSelectedCity] = useState(null);
  const [airportId, setAirportId] = useState();

  const [modalCountry, setModalCountry] = useState(null);
  const [modalStates, setModalStates] = useState([]);
  const [modalState, setModalState] = useState(null);
  const [modalCities, setModalCities] = useState([]);
  const [modalCity, setModalCity] = useState(null);

  const [showModal, setShowModal] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [editingAirportId, setEditingAirportId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const [editState, setEditState] = useState(null);
  const [editCity, setEditCity] = useState(null);

  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}country`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        setCountries(response.data.data || []);
      } catch (error) {
        console.error("Error fetching countries:", error);
      }
    };
    fetchCountries();
  }, []);

  useEffect(() => {
    if (!selectedCountry) return;

    const fetchAirports = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}airports/${selectedCountry}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        const fetchedAirports = response.data.data || [];
        setAirports(fetchedAirports);
        setTotalPages(Math.ceil(fetchedAirports.length / itemsPerPage));
      } catch (error) {
        console.error("Error fetching airports:", error);
        setAirports([]);
      } finally {
        setLoading(false);
      }
    };
    fetchAirports();
  }, [selectedCountry, itemsPerPage]);

  useEffect(() => {
    if (!modalCountry) return;

    const fetchStates = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}state/${modalCountry}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        if (!editMode) {
          setModalStates(response.data.data || []);
          setModalState(null);
          setModalCities([]);
          setModalCity(null);
        }
      } catch (error) {
        console.error("Error fetching states:", error);
      }
    };
    fetchStates();
  }, [modalCountry]);

  useEffect(() => {
    if (!modalState) return;

    const fetchCities = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}city/${modalState}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        if (!editMode) {
          setModalCities(response.data.data || []);
          setModalCity(null);
        }
      } catch (error) {
        console.error("Error fetching cities:", error);
      }
    };
    fetchCities();
  }, [modalState]);

  const handleAddAirport = async () => {
    if (!airportName || !airportIataCode || !modalCity) {
      alert("Please fill all required fields!");
      return;
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}airport`,
        {
          airport_name: airportName,
          airport_iata_code: airportIataCode,
          city_id: modalCity,
          latitude,
          longitude,
        },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      alert(response.data.message || "Airport added successfully!");
      setAirportIataCode(null);
      setModalCity(null);
      setModalState(null);
      setModalCountry(null);
      setAirportName(null);
      setLatitude(null);
      setLongitude(null);
      setActive(false);

      setShowModal(false);
    } catch (error) {
      console.error("Error adding airport:", error);
      alert("Error adding airport. Please try again.");
    }
  };

  const stateEdit = async (id, country_id) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}state/${country_id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      setModalStates(response.data.data || []);
      setEditState(id);
    } catch (error) {
      console.error("Error fetching states:", error);
    }
  };
  const cityEdit = async (id, state_id) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}city/${state_id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      setModalCities(response.data.data || []);
      setEditCity(id);
    } catch (error) {
      console.error("Error fetching cities:", error);
    }
  };
  const handleEditAirport = async (airport) => {
    setEditMode(true);
    setEditingAirportId(airport.airport_id);
    setAirportName(airport.airport_name);
    setAirportIataCode(airport.airport_iata_code);
    setLatitude(airport.latitude || "");
    setLongitude(airport.longitude || "");
    setActive(airport.active);
    setModalCountry(airport.country_id);

    setAirportId(airport.airport_id);
    stateEdit(airport.state_id, airport.country_id);
    cityEdit(airport.city_id, airport.state_id);

    setShowModal(true);
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = airports.slice(indexOfFirstItem, indexOfLastItem);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleEditsAirport = async () => {
    if (!airportName || !airportIataCode || !editCity) {
      alert("Please fill all required fields!");
      return;
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}updateAirport`,
        {
          airport_id: airportId,
          airport_name: airportName,
          airport_iata_code: airportIataCode,
          city_id: editCity,
          latitude,
          longitude,
          active,
        },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      alert(response.data.message || "Airport Edit successfully!");
      setAirportIataCode(null);
      setModalCity(null);
      setModalState(null);
      setModalCountry(null);
      setAirportName(null);
      setLatitude(null);
      setLongitude(null);
      setActive(false);
      setEditMode(false);
      setShowModal(false);
      setSelectedCountry(selectedCountry);

      setAirportId(null);
    } catch (error) {
      console.error("Error adding airport:", error);
      alert("Error adding airport. Please try again.");
    }
  };

  return (
    <div className="container mt-3">
      <div className="d-flex justify-content-between align-items-center mb-3">
        <h2>Airport Manager</h2>
        <Button variant="primary" onClick={() => setShowModal(true)}>
          Add Airport
        </Button>
      </div>

      <div className="card bg-light">
        <div className="card-body">
          <div className="mb-3">
            <label htmlFor="countrySelect" className="form-label mt-4">
              Select Country
            </label>
            <select
              id="countrySelect"
              className="form-select"
              value={selectedCountry || ""}
              onChange={(e) => setSelectedCountry(e.target.value)}
            >
              <option value="">Select a country</option>
              {countries.map((country) => (
                <option key={country.country_id} value={country.country_id}>
                  {country.country_name}
                </option>
              ))}
            </select>
          </div>
          {loading ? (
            <div className="d-flex justify-content-center align-items-center">
              <div className="spinner-border text-primary" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          ) : airports.length === 0 ? (
            <div className="text-center mt-3">
              <h5>No Airports Found</h5>
            </div>
          ) : (
            <div className="mt-3">
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th>SNo.</th>
                    <th>Airport IATA Code</th>
                    <th>Airport Name</th>
                  </tr>
                </thead>
                <tbody>
                  {currentItems.map((airport, index) => (
                    <tr key={airport.airport_id}>
                      <td>{indexOfFirstItem + index + 1}</td>
                      <td>
                        <span
                          className="text-primary cursor-pointer"
                          onClick={() => handleEditAirport(airport)}
                        >
                          {airport.airport_iata_code}
                        </span>
                      </td>
                      <td>{airport.airport_name}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className="d-flex justify-content-center mt-3">
                <button
                  className="btn btn-primary me-2"
                  onClick={handlePreviousPage}
                  disabled={currentPage === 1}
                >
                  Previous
                </button>
                <span className="align-self-center">
                  Page {currentPage} of {totalPages}
                </span>
                <button
                  className="btn btn-primary ms-2"
                  onClick={handleNextPage}
                  disabled={currentPage === totalPages}
                >
                  Next
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{editMode ? "Edit Airport" : "Add Airport"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
            {/* Country Selection */}
            <div className="mb-3">
              <label htmlFor="modalCountry" className="form-label">
                Country
              </label>
              <select
                id="modalCountry"
                className="form-select"
                value={modalCountry || ""}
                onChange={(e) => setModalCountry(e.target.value)}
              >
                <option value="">Select a country</option>
                {countries.map((country) => (
                  <option key={country.country_id} value={country.country_id}>
                    {country.country_name}
                  </option>
                ))}
              </select>
            </div>

            {/* State Selection */}
            <div className="mb-3">
              <label htmlFor="modalState" className="form-label">
                State
              </label>
              <select
                id="modalState"
                className="form-select"
                value={editMode ? editState : modalState || ""}
                onChange={(e) =>
                  editMode
                    ? setEditState(e.target.value)
                    : setModalState(e.target.value)
                }
              >
                <option value="">Select a state</option>
                {modalStates.map((state) => (
                  <option key={state.state_id} value={state.state_id}>
                    {state.state_name}
                  </option>
                ))}
              </select>
            </div>

            {/* City Selection */}
            <div className="mb-3">
              <label htmlFor="modalCity" className="form-label">
                City
              </label>
              <select
                id="modalCity"
                className="form-select"
                value={editMode ? editCity : modalCity || ""}
                onChange={(e) =>
                  editMode
                    ? setEditCity(e.target.value)
                    : setModalCity(e.target.value)
                }
              >
                <option value="">Select a city</option>
                {modalCities.map((city) => (
                  <option key={city.city_id} value={city.city_id}>
                    {city.city_name}
                  </option>
                ))}
              </select>
            </div>

            {/* Other Fields */}
            <div className="mb-3">
              <label htmlFor="airportName" className="form-label">
                Airport Name
              </label>
              <input
                type="text"
                id="airportName"
                className="form-control"
                value={airportName}
                onChange={(e) => setAirportName(e.target.value)}
                required
              />
            </div>
            <div className="mb-3">
              <label htmlFor="airportIataCode" className="form-label">
                IATA Code
              </label>
              <input
                type="text"
                id="airportIataCode"
                className="form-control"
                value={airportIataCode}
                onChange={(e) => setAirportIataCode(e.target.value)}
                required
              />
            </div>
            <div className="mb-3">
              <label htmlFor="latitude" className="form-label">
                Latitude
              </label>
              <input
                type="text"
                id="latitude"
                className="form-control"
                value={latitude}
                onChange={(e) => setLatitude(e.target.value)}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="longitude" className="form-label">
                Longitude
              </label>
              <input
                type="text"
                id="longitude"
                className="form-control"
                value={longitude}
                onChange={(e) => setLongitude(e.target.value)}
              />
            </div>
            <div className="form-check">
              <input
                type="checkbox"
                id="active"
                className="form-check-input"
                checked={active}
                onChange={(e) => setActive(e.target.checked)}
              />
              <label htmlFor="active" className="form-check-label">
                Active
              </label>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setAirportIataCode(null);
              setModalCity(null);
              setModalState(null);
              setModalCountry(null);
              setAirportName(null);
              setLatitude(null);
              setLongitude(null);
              setActive(false);
              setEditMode(false);
              setShowModal(false);
              setAirportId(null);
            }}
          >
            Close
          </Button>
          <Button
            variant="primary"
            onClick={editMode ? handleEditsAirport : handleAddAirport}
          >
            {editMode ? "Update Airport" : "Add Airport"}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default AirportManager;
