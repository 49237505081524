import React from "react";

import Sidebar from "../components/Sidebar";
import NavbarComponent from "../components/Navbar";
import SearchTravelPlans from "../components/SearchTravelPlans";

const SearchFrom = () => {
  return (
    <>
      <NavbarComponent />
      <div className="d-flex">
        <Sidebar />
        <div className="flex-grow-1">
          <SearchTravelPlans />
        </div>
      </div>
    </>
  );
};

export default SearchFrom;
