import React, { useEffect, useState } from "react";
import axios from "axios";
import { useDispatch } from "react-redux";
import "bootstrap/dist/js/bootstrap.bundle.min.js";

import {
  addConfirmationCode,
  removeConfirmationCode,
} from "../utils/ConfirmationSlice";
import { useNavigate } from "react-router-dom";

const RecentBooking = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [countryList, setCountryList] = useState([]);
  const [countryInfo, setCountryInfo] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [bookings, setBookings] = useState([]);
  const [loading, setLoading] = useState(false);

  // Fetch country list
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}country`, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      })
      .then((response) => {
        setCountryList(response.data.data);

        // Default selection (first country in the list)
        if (response.data.data?.length) {
          const defaultCountry = response.data.data[0];

          setCountryInfo({
            countryCode: defaultCountry.country_code,
            countryName: defaultCountry.country_name,
            countryPhoneCode: defaultCountry.country_phone_code,
            countryFlagImagePath: `${process.env.REACT_APP_ASSETS_URL}${defaultCountry.country_code}.png`,
          });
        }
      })
      .catch((error) => {
        console.error("Error fetching country list:", error);
      });
  }, []);

  // Handle API call for bookings
  const fetchBookings = () => {
    if (!countryInfo || !phoneNumber) {
      alert("Please select a country and enter a phone number.");
      return;
    }

    setLoading(true);

    axios
      .post(
        `${process.env.REACT_APP_API_URL}travelPlans/phone-number`,
        {
          country_phone_code: countryInfo.countryPhoneCode,
          phone_number: phoneNumber,
        },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      )
      .then((response) => {
        setBookings(response.data?.data || []);
      })
      .catch((error) => {
        console.error("Error fetching bookings:", error);
        setBookings([]);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleBookingClick = (confirmation_code) => {
    dispatch(addConfirmationCode(confirmation_code));
    navigate("/");
  };

  return (
    <div className="container mt-3">
      <h2>Recent Booking</h2>

      {/* Country and Phone Number Selection */}
      <div className="card p-3">
        <div className="d-flex align-items-center mb-3">
          {/* Country Dropdown */}
          <div className="dropdown me-3">
            <button
              className="btn btn-outline-secondary dropdown-toggle"
              type="button"
              id="dropdownMenuButton"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              {countryInfo && (
                <>
                  <img
                    src={countryInfo.countryFlagImagePath}
                    alt=""
                    className="me-2"
                    style={{ width: "24px" }}
                  />
                  {countryInfo.countryName} ({countryInfo.countryPhoneCode})
                </>
              )}
            </button>
            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
              {countryList.map((country, i) => (
                <li key={i}>
                  <button
                    className="dropdown-item d-flex align-items-center"
                    onClick={() =>
                      setCountryInfo({
                        countryCode: country.country_code,
                        countryName: country.country_name,
                        countryPhoneCode: country.country_phone_code,
                        countryFlagImagePath: `${process.env.REACT_APP_ASSETS_URL}${country.country_code}.png`,
                      })
                    }
                  >
                    <img
                      src={`${process.env.REACT_APP_ASSETS_URL}${country.country_code}.png`}
                      alt=""
                      className="me-2"
                      style={{ width: "20px" }}
                    />
                    {country.country_name} ({country.country_phone_code})
                  </button>
                </li>
              ))}
            </ul>
          </div>

          {/* Phone Number Input */}
          <input
            type="text"
            className="form-control"
            placeholder="Phone Number"
            value={phoneNumber}
            onChange={(e) => {
              const enteredValue = e.target.value;
              const onlyDigits = enteredValue.replace(/[^\d]/g, ""); // Remove non-digit characters
              setPhoneNumber(onlyDigits);
            }}
            maxLength={10}
          />

          {/* Fetch Bookings Button */}
          <button
            className="btn btn-primary ms-3"
            onClick={fetchBookings}
            disabled={loading}
          >
            {loading ? "Loading..." : "Search"}
          </button>
        </div>
      </div>

      {/* Bookings Table */}
      <div className="card bg-light mt-3">
        <div className="card-body">
          {loading ? (
            <div className="d-flex justify-content-center align-items-center">
              <div className="spinner-border text-primary" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          ) : bookings.length ? (
            <table className="table table-striped">
              <thead>
                <tr>
                  <th>S.No.</th>
                  <th>Confirmation Code</th>
                  <th>Start Date of Travel</th>
                  <th>Created At</th>
                  <th>Active</th>
                </tr>
              </thead>
              <tbody>
                {bookings.map((booking, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td
                      className="text-primary text-decoration-underline"
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        handleBookingClick(booking.confirmation_code)
                      }
                    >
                      {booking.confirmation_code}
                    </td>
                    <td>
                      {new Date(
                        booking.start_date_of_travel
                      ).toLocaleDateString("en-GB")}
                    </td>
                    <td>
                      {new Date(booking.created_at).toLocaleDateString("en-GB")}
                    </td>
                    <td>{booking?.active ? "Yes" : "No"}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <p className="text-center">No Travel Plan found.</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default RecentBooking;
