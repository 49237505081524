import React, { useState, useEffect } from "react";
import axios from "axios";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Form, Button, Spinner, Alert, Row, Col } from "react-bootstrap";
import {
  addConfirmationCode,
  removeConfirmationCode,
} from "../utils/ConfirmationSlice";
const App = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [countries, setCountries] = useState([]);
  const [fromCities, setFromCities] = useState([]);
  const [toCities, setToCities] = useState([]);
  const [travelPlans, setTravelPlans] = useState([]);
  const [selectedFromCountry, setSelectedFromCountry] = useState("");
  const [selectedToCountry, setSelectedToCountry] = useState("");
  const [fromCity, setFromCity] = useState("");
  const [toCity, setToCity] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [filter, setFilter] = useState("No Action");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;

  const totalPages = Math.ceil(travelPlans?.length / itemsPerPage);
  const indexOfFirstItem = (currentPage - 1) * itemsPerPage;
  const currentItems = travelPlans?.slice(
    indexOfFirstItem,
    indexOfFirstItem + itemsPerPage
  );

  // Fetch countries
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}country`, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      })
      .then((response) => {
        setCountries(response.data.data);
        dispatch(removeConfirmationCode());
      })

      .catch(() => setErrorMessage("Failed to fetch countries."));
  }, []);

  // Fetch cities for selected countries
  useEffect(() => {
    if (selectedFromCountry) {
      fetchCities(selectedFromCountry, setFromCities);
    }
  }, [selectedFromCountry]);

  useEffect(() => {
    if (selectedToCountry) {
      fetchCities(selectedToCountry, setToCities);
    }
  }, [selectedToCountry]);

  const fetchCities = (countryId, setCities) => {
    setLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}cities/${countryId}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      })
      .then((response) => {
        setTravelPlans([]);
        setSuccessMessage("");
        setCities(response.data.data);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        setErrorMessage("Failed to fetch cities for the selected country.");
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!fromCity || !toCity || !startDate || !endDate) {
      setErrorMessage("All fields are required.");
      return;
    }

    const payload = {
      from_city_id: fromCity,
      to_city_id: toCity,
      start_date_of_travel: startDate,
      end_date_of_travel: endDate,
    };

    setLoading(true);
    setErrorMessage("");
    setSuccessMessage("");

    axios
      .post(`${process.env.REACT_APP_API_URL}travelPlans`, payload, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      })
      .then((response) => {
        if (response.status == 202) {
          setSuccessMessage("No travel plans found for the given conditions.");
        } else {
          setTravelPlans(response.data.data);
        }
        // Reset the form
        setFromCity("");
        setToCity("");
        setStartDate("");
        setEndDate("");
        setSelectedFromCountry("");
        setSelectedToCountry("");
        setFromCities([]);
        setToCities([]);

        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        setErrorMessage("Failed to submit the travel plan.");
      });
  };

  const resetForm = () => {
    setFromCity("");
    setToCity("");
    setStartDate("");
    setEndDate("");
    setSelectedFromCountry("");
    setSelectedToCountry("");
    setFromCities([]);
    setToCities([]);
    setLoading(false);
  };
  const handleChange = (confirmation_code) => {
    dispatch(addConfirmationCode(confirmation_code));
    navigate("/");
  };

  return (
    <div className="container mt-4">
      {/* Travel Planner Form */}
      <Form onSubmit={handleSubmit} className="mb-3">
        <Row>
          <Col md={6}>
            <Form.Group controlId="fromCountry" className="mb-3">
              <Form.Label>From Country</Form.Label>
              <Form.Select
                value={selectedFromCountry}
                onChange={(e) => {
                  setSelectedFromCountry(e.target.value);
                  setFromCity("");
                }}
              >
                <option value="">Select a country</option>
                {countries?.map((country) => (
                  <option key={country.country_id} value={country.country_id}>
                    {country.country_name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>

            <Form.Group controlId="fromCity" className="mb-3">
              <Form.Label>From City</Form.Label>
              <Form.Select
                value={fromCity}
                onChange={(e) => setFromCity(e.target.value)}
                disabled={!fromCities?.length}
              >
                <option value="">Select a city</option>
                {fromCities?.map((city) => (
                  <option key={city.city_id} value={city.city_id}>
                    {city.city_name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>

            <Form.Group controlId="startDate" className="mb-3">
              <Form.Label>From Date</Form.Label>
              <Form.Control
                type="date"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
              />
            </Form.Group>
          </Col>

          <Col md={6}>
            <Form.Group controlId="toCountry" className="mb-3">
              <Form.Label>To Country</Form.Label>
              <Form.Select
                value={selectedToCountry}
                onChange={(e) => {
                  setSelectedToCountry(e.target.value);
                  setToCity("");
                }}
              >
                <option value="">Select a country</option>
                {countries?.map((country) => (
                  <option key={country.country_id} value={country.country_id}>
                    {country.country_name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>

            <Form.Group controlId="toCity" className="mb-3">
              <Form.Label>To City</Form.Label>
              <Form.Select
                value={toCity}
                onChange={(e) => setToCity(e.target.value)}
                disabled={!toCities?.length}
              >
                <option value="">Select a city</option>
                {toCities?.map((city) => (
                  <option key={city.city_id} value={city.city_id}>
                    {city.city_name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>

            <Form.Group controlId="endDate" className="mb-3">
              <Form.Label>To Date</Form.Label>
              <Form.Control
                type="date"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
              />
            </Form.Group>
          </Col>
        </Row>

        <Button variant="primary" type="submit" disabled={loading}>
          {loading ? <Spinner animation="border" size="sm" /> : "Submit"}
        </Button>
      </Form>

      {/* Travel Plans Table */}
      {successMessage ? (
        <h5>{successMessage}</h5>
      ) : (
        travelPlans?.length > 0 && (
          <div className="card bg-light">
            <div className="card-body">
              {loading ? (
                <div className="d-flex justify-content-center align-items-center">
                  <Spinner animation="border" />
                </div>
              ) : (
                <>
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th>SNo.</th>
                        <th>Confirmation Code</th>
                        <th>Start Date</th>
                        <th>End Date</th>
                        <th>Created At</th>
                      </tr>
                    </thead>
                    <tbody>
                      {currentItems?.map((item, index) => (
                        <tr key={index}>
                          <td>{indexOfFirstItem + index + 1}</td>
                          <td
                            onClick={() =>
                              handleChange(item?.confirmation_code)
                            }
                            style={{
                              cursor: "pointer",
                              color: "blue",
                              fontWeight: "bold",
                              textDecoration: "underline",
                            }}
                          >
                            {item?.confirmation_code}
                          </td>
                          <td>
                            {new Date(
                              item.start_date_of_travel
                            ).toLocaleDateString()}
                          </td>
                          <td>
                            {new Date(
                              item.end_date_of_travel
                            ).toLocaleDateString()}
                          </td>
                          <td>{new Date(item.created_at).toLocaleString()}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>

                  <div className="d-flex justify-content-center mt-3">
                    <Button
                      variant="primary m-2"
                      onClick={() =>
                        currentPage > 1 && setCurrentPage(currentPage - 1)
                      }
                      disabled={currentPage === 1}
                    >
                      Previous
                    </Button>
                    <span className="m-3">
                      Page {currentPage} of {totalPages}
                    </span>
                    <Button
                      variant="primary m-2"
                      onClick={() =>
                        currentPage < totalPages &&
                        setCurrentPage(currentPage + 1)
                      }
                      disabled={currentPage === totalPages}
                    >
                      Next
                    </Button>
                  </div>
                </>
              )}
            </div>
          </div>
        )
      )}
    </div>
  );
};

export default App;
