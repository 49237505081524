import React, { useEffect, useState } from "react";
import axios from "axios";
import { useDispatch } from "react-redux";
import {
  addConfirmationCode,
  removeConfirmationCode,
} from "../utils/ConfirmationSlice";
import { useNavigate } from "react-router-dom";

const RecentRequest = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [progress, setProgress] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10); // Number of items per page
  const [loading, setLoading] = useState(false); // State for loading
  const [filter, setFilter] = useState("No Action"); // Default filter

  // Fetch requests based on the selected filter
  useEffect(() => {
    const fetchRequests = async () => {
      setLoading(true); // Set loading to true when the request starts
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}recent_request?status=${filter}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        setProgress(response?.data?.data || []);
      } catch (error) {
        console.error("Error fetching requests:", error);
        setProgress([]);
      } finally {
        setLoading(false); // Set loading to false after data is fetched
      }
    };

    fetchRequests();
    dispatch(removeConfirmationCode());
  }, [filter, dispatch]);

  const handleChange = (confirmation_code) => {
    dispatch(addConfirmationCode(confirmation_code));
    navigate("/");
  };

  const handleFilterChange = (event) => {
    setFilter(event.target.value);
    setCurrentPage(1); // Reset to the first page on filter change
  };

  // Pagination logic
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = progress.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(progress.length / itemsPerPage);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    <div>
      <div className="container mt-3">
        <h2>Recent Requests</h2>
        <div className="card bg-light">
          <div className="card-body">
            {/* Dropdown for filtering */}
            <div className="mb-3">
              <label htmlFor="filter" className="form-label"></label>
              <select
                id="filter"
                className="form-select"
                value={filter}
                onChange={handleFilterChange}
              >
                <option value="No Action">No Action</option>
                <option value="Received">Received</option>
                <option value="Sent">Sent</option>
                <option value="Approved">Approved</option>
                <option value="Declined">Declined</option>
              </select>
            </div>
            {loading ? (
              <div className="d-flex justify-content-center align-items-center">
                <div className="spinner-border text-primary" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              </div>
            ) : progress.length === 0 ? (
              <div className="text-center mt-3">
                <h5>No Recent Request Found</h5>
              </div>
            ) : (
              <div className="container mt-3">
                <div className="card bg-light">
                  <div className="card-body overflow-auto">
                    <table className="table table-striped overflow-auto">
                      <thead>
                        <tr>
                          <th>SNo.</th>
                          <th>Confirmation Code</th>
                          <th>Start Date of Travel</th>
                          <th>Match Date & Time</th>
                        </tr>
                      </thead>
                      <tbody>
                        {currentItems?.map((item, index) => (
                          <tr key={index}>
                            <td>{indexOfFirstItem + index + 1}</td>
                            <td
                              onClick={() =>
                                handleChange(item?.confirmation_code)
                              }
                              style={{
                                cursor: "pointer",
                                color: "blue",
                                fontWeight: "bold",
                                textDecoration: "underline",
                              }}
                            >
                              {item?.confirmation_code}
                            </td>

                            <td>
                              {new Date(item?.start_date_of_travel)
                                .toLocaleDateString("en-GB", {
                                  day: "2-digit",
                                  month: "2-digit",
                                  year: "numeric",
                                })
                                .replace(/\//g, "/")}
                            </td>
                            <td>
                              {" "}
                              {new Date(item.match_date_time).toLocaleString(
                                "en-US",
                                {
                                  dateStyle: "medium",
                                  timeStyle: "medium",
                                }
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    {/* Pagination controls */}
                    <div className="d-flex justify-content-center mt-3 ">
                      <button
                        className="btn btn-primary me-2"
                        onClick={handlePreviousPage}
                        disabled={currentPage === 1}
                      >
                        Previous
                      </button>
                      <span className="align-self-center">
                        Page {currentPage} of {totalPages}
                      </span>
                      <button
                        className="btn btn-primary ms-2"
                        onClick={handleNextPage}
                        disabled={currentPage === totalPages}
                      >
                        Next
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RecentRequest;
