import React, { useState, useEffect } from "react";
import axios from "axios";
import { Table, Pagination, Modal, Button } from "react-bootstrap";

const AgeRange = () => {
  const [flightInfoData, setFlightInfoData] = useState([]);
  const [fromAirport, setFromAirports] = useState("");
  const [toAirport, setToAirports] = useState("");
  const [airports, setAirports] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage] = useState(15);
  const [countryCode, setCountryCode] = useState("");
  const [countryName, setCountryName] = useState("");
  const [fromAirports, setFromAirport] = useState("");
  const [toAirports, setToAirport] = useState("");
  const [arrivalTime, setArrivalTime] = useState("");
  const [checkUpdate, setCheckUpdate] = useState(null);
  const [airlineOptions, setAirlineOptions] = useState();

  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = airports?.slice(indexOfFirstRow, indexOfLastRow);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    axios

      .get(`${process.env.REACT_APP_API_URL}flightinformation`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })

      .then((response) => {
        setFlightInfoData(response.data.data);
      })
      .catch((error) => {});
  }, [showModal]);

  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(airports?.length / rowsPerPage); i++) {
    pageNumbers.push(i);
  }

  useEffect(() => {
    axios

      .get(`${process.env.REACT_APP_API_URL}age`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })

      .then((response) => {
        setAirports(response.data.data);

        //(response.data.data);
      })
      .catch((error) => {
        //(error);
      });
  }, [showModal]);

  return (
    <div>
      <div>
        <div className="d-flex justify-content-between align-items-center mb-3">
          <h1>Age Range</h1>
        </div>

        <Table striped bordered hover>
          <thead>
            <tr>
              <th>SNo.</th>
              <th>Age Range Name</th>
              <th>Active</th>
            </tr>
          </thead>
          <tbody>
            {currentRows?.map((flightInfo, index) => (
              <tr key={flightInfo?.flight_id}>
                <td>{indexOfFirstRow + index + 1}</td>
                <td>{flightInfo?.age_range_name}</td>
                <td>{flightInfo?.active}</td>
              </tr>
            ))}
          </tbody>
        </Table>

        <div className="d-flex justify-content-center"></div>
      </div>
    </div>
  );
};

export default AgeRange;
