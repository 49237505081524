import React, { useEffect, useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";

import { Modal, Button } from "react-bootstrap";

const FetchDetails = (data) => {
  const Code = useSelector((state) => state.ConfirmationCode.confirmationCode);

  useEffect(() => {
    if (Code) {
      handleShowCustomer();
      setActiveTab("Customer");
    }
  }, [Code]);

  const finalData = data.data?.searchResult;

  //(finalData);

  const confirmationCode = data.data?.confirmationCode;

  const customer = finalData?.customer;

  const TravelPlan = finalData?.travelPlan;
  const Traveller = finalData?.traveller;
  const Matches = finalData?.match;
  const Ticket = finalData?.ticket;
  const companionOnboarding = finalData?.companionOnboarding;
  const payout = finalData?.payout;

  let travellers;

  if (Traveller && Traveller.length > 0) {
    travellers = Traveller[0].travel_plan_id;
  }

  const filteredTravellers = Traveller?.filter(
    (traveller) => traveller.travel_plan_id === travellers
  );

  //(filteredTravellers);

  const payment = finalData?.payment;

  const notification = finalData?.notification;

  const feedback = finalData?.feedback;

  const transitStatus = finalData?.transitStatus;
  const Offering = finalData?.offering;
  const Notes = finalData?.notes;

  const [showModal, setShowModal] = useState(false);
  const [refundAmount, setRefundAmount] = useState(""); // State to track refund amount
  const [currency, setCurrency] = useState(""); // State to track currency
  const [paymentIntentId, setPaymentIntentId] = useState("");
  const [feedbackSubject, setFeedbackSubject] = useState([]); // State to track feedback subject
  const [feedbackMessage, setFeedbackMessage] = useState("");
  const [totalRefunded, setTotalRefunded] = useState(0); // State to track feedback message

  const [selectedFeedback, setSelectedFeedback] = useState("");
  const [amount, setAmount] = useState(0);
  const [remarks, setRemarks] = useState("");
  const [showRemarksModal, setShowRemarksModal] = useState(false);
  const [currentTicketId, setCurrentTicketId] = useState(null);

  const [showRemarksModalOffering, setShowRemarksModalOffering] =
    useState(false);
  const [currentOfferingId, setCurrentOfferingId] = useState(null);
  const [remarksOffering, setRemarksOffering] = useState("");
  const [showRemarksModalPayout, setShowRemarksModalPayout] = useState(false);
  const [currentPayoutId, setCurrentPayoutId] = useState(null);
  const [remarksPayout, setRemarksPayout] = useState("");

  // State Management
  const [showRemarksModalEarnings, setShowRemarksModalEarnings] =
    useState(false);
  const [currentEarningsId, setCurrentEarningsId] = useState(null);
  const [remarksEarnings, setRemarksEarnings] = useState("");

  // Handle Actions
  const handleActionEarnings = async (id, status, remarksEarnings = null) => {
    const headers = {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      "Content-Type": "application/json",
    };

    try {
      // Ensure remarks are provided if status is "REJECTED"
      if (
        status === "REJECTED" &&
        (!remarksEarnings || remarksEarnings.trim() === "")
      ) {
        alert("Remarks are required for rejecting earnings.");
        return;
      }

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}earning/update`,
        {
          id,
          status,
          remarksEarnings,
        },
        { headers }
      );

      // Handle success response
      alert("Action Successful");
      data.data.check(true); // Reload or update state to reflect changes
    } catch (error) {
      // Handle error response

      alert(
        error.response?.data?.message || "An error occurred. Please try again."
      );
    }
  };

  const handleRejectEarnings = (earningsId) => {
    setCurrentEarningsId(earningsId);
    setShowRemarksModalEarnings(true);
  };

  const submitRejectionEarnings = () => {
    if (remarksEarnings.trim() === "") {
      alert("Remarks are required for rejecting these earnings.");
      return;
    }
    handleActionEarnings(currentEarningsId, "REJECTED", remarksEarnings);
    setShowRemarksModalEarnings(false);
    setRemarksEarnings("");
  };

  const handleRemarksCloseEarnings = () => {
    setShowRemarksModalEarnings(false);
    setRemarksEarnings("");
  };

  const handleActionPayout = async (id, status, remarksPayout = null) => {
    const headers = {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      "Content-Type": "application/json",
    };

    try {
      // Ensure remarks are provided if status is "REJECTED"
      if (
        status === "REJECTED" &&
        (!remarksPayout || remarksPayout.trim() === "")
      ) {
        alert("Remarks are required for rejecting a payout.");
        return;
      }

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}payout/update`,
        {
          id,
          status,
          remarksPayout, // Include remarks in the payload
        },
        { headers }
      );

      // Handle success response
      alert("Action Successful");
      data.data.check(true); // Reload or update state to reflect changes
    } catch (error) {
      // Handle error response

      alert(
        error.response?.data?.message || "An error occurred. Please try again."
      );
    }
  };

  const handleRejectPayout = (payoutId) => {
    setCurrentPayoutId(payoutId);
    setShowRemarksModalPayout(true);
  };

  const submitRejectionPayout = () => {
    if (remarksPayout.trim() === "") {
      alert("Remarks are required for rejecting this payout.");
      return;
    }
    handleActionPayout(currentPayoutId, "REJECTED", remarksPayout);
    setShowRemarksModalPayout(false);
    setRemarksPayout("");
  };

  const handleRemarksClosePayout = () => {
    setShowRemarksModalPayout(false);
    setRemarksPayout("");
  };

  const handleActionOffering = async (id, status, remarksOffering = null) => {
    const headers = {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      "Content-Type": "application/json",
    };

    try {
      // Ensure remarks are provided if status is "REJECTED"
      if (
        status === "REJECTED" &&
        (!remarksOffering || remarksOffering.trim() === "")
      ) {
        alert("Remarks are required for rejecting a ticket.");
        return;
      }

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}offer/update`,
        {
          id,
          status,
          remarksOffering, // Include remarks in the payload
        },
        { headers }
      );

      // Handle success response
      alert("Action Successful");
      data.data.check(true); // Reload or update state to reflect changes
    } catch (error) {
      // Handle error response

      alert(
        error.response?.data?.message || "An error occurred. Please try again."
      );
    }
  };
  const handleRejectOffering = (offeringId) => {
    setCurrentOfferingId(offeringId);
    setShowRemarksModalOffering(true);
  };

  const submitRejectionOffering = () => {
    if (remarksOffering.trim() === "") {
      alert("Remarks are required for rejecting this offering.");
      return;
    }
    handleActionOffering(currentOfferingId, "REJECTED", remarksOffering);
    setShowRemarksModalOffering(false);
    setRemarksOffering("");
  };

  const handleRemarksCloseOffering = () => {
    setShowRemarksModalOffering(false);
    setRemarksOffering("");
  };
  const [showRemarksModalCustomer, setShowRemarksModalCustomer] =
    useState(false);
  const [currentCustomerId, setCurrentCustomerId] = useState(null);
  const [remarksCustomer, setRemarksCustomer] = useState("");

  const handleActionCustomer = async (id, status, remarksCustomer = null) => {
    const headers = {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      "Content-Type": "application/json",
    };

    try {
      if (
        status === "REJECTED" &&
        (!remarksCustomer || remarksCustomer.trim() === "")
      ) {
        alert("Remarks are required for rejecting a customer.");
        return;
      }

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}customer/update`,
        {
          id,
          status,
          remarksCustomer,
        },
        { headers }
      );

      alert("Action Successful");
      data.data.check(true);
    } catch (error) {
      alert(
        error.response?.data?.message || "An error occurred. Please try again."
      );
    }
  };

  const handleRejectCustomer = (customerId) => {
    setCurrentCustomerId(customerId);
    setShowRemarksModalCustomer(true);
  };

  const submitRejectionCustomer = () => {
    if (remarksCustomer.trim() === "") {
      alert("Remarks are required for rejecting this customer.");
      return;
    }
    handleActionCustomer(currentCustomerId, "REJECTED", remarksCustomer);
    setShowRemarksModalCustomer(false);
    setRemarksCustomer("");
  };

  const handleRemarksCloseCustomer = () => {
    setShowRemarksModalCustomer(false);
    setRemarksCustomer("");
  };

  const handleFeedbackChange = (event) => {
    setSelectedFeedback(event.target.value);
  };

  const handleRefund = (
    amount,
    currency,
    payment_intent_id,
    refunded_amount
  ) => {
    setTotalRefunded(refunded_amount);
    setAmount(amount);
    setCurrency(currency);
    setPaymentIntentId(payment_intent_id);
    setRefundAmount(amount);
    setShowModal(true);
    if (refunded_amount > 0) {
      setRefundType("partial");
      setRefundAmount("");
    }
  };

  const handleCloseModal = () => {
    setTotalRefunded("");
    setAmount("");
    setRefundAmount("");
    setCurrency("");
    setPaymentIntentId("");
    setRefundType("full");
    setShowModal(false);
  };

  const submitRefund = () => {
    //(refundAmount);
    //(amount);
    if (refundAmount <= 0) {
      alert("Please enter a valid amount");
      return;
    }
    alert(`${parseFloat(refundAmount) > parseFloat(amount)}`);
    if (parseFloat(refundAmount) > parseFloat(amount)) {
      alert("Refund amount cannot be greater than the total amount");
      return;
    }
    alert(`${refundAmount}, ${amount}, ${totalRefunded}`);
    if (refundAmount > amount - totalRefunded) {
      alert("Refund amount cannot be greater than the total amount");
      return;
    }

    const headers = {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      "Content-Type": "application/json",
    };

    //(totalRefunded);

    const body = {
      paymentIntentId: paymentIntentId,
      refundAmount: refundAmount,
      amount: amount,
      totalRefunded: totalRefunded,
    };

    axios
      .post(`${process.env.REACT_APP_API_URL}refund-payment`, body, {
        headers,
      })
      .then((response) => {
        alert("Refund Successful");
        data.data.check(true);
      })
      .catch((error) => {
        // Handle the error here
      });

    handleCloseModal();
  };
  const appNotifications = notification?.filter(
    (notif) => notif.message_type === "APP"
  );
  const smsNotifications = notification?.filter(
    (notif) => notif.message_type === "SMS"
  );
  const emailNotifications = notification?.filter(
    (notif) => notif.message_type === "EMAIL"
  );
  const webNotifications = notification?.filter(
    (notif) => notif.message_type === "WEB"
  );
  const [selectedMessageType, setSelectedMessageType] = useState(
    appNotifications?.length > 0 ? "APP" : "SMS"
  );

  const fetchFeedbackSubject = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}feedback`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
          },
        }
      );
      //(response.data.data);
      setFeedbackSubject(response.data.data);
    } catch (error) {
      alert(error?.response?.data?.data || "Something went wrong!");
    }
  };

  useEffect(() => {
    fetchFeedbackSubject();
  }, []);

  const handleFeedbackSubmit = async () => {
    //(selectedFeedback);

    const headers = {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      "Content-Type": "application/json",
    };

    const body = {
      feedback_subject_id: selectedFeedback,
      message: feedbackMessage,
      confirmation_code: confirmationCode,
    };

    //(body);

    axios
      .post(`${process.env.REACT_APP_API_URL}addfeedback`, body, {
        headers,
      })
      .then((response) => {
        alert("Feedback Submitted");
        setFeedbackMessage("");
        setSelectedFeedback("");

        data.data.check(true);
      })
      .catch((error) => {});
  };
  const handleNotesSubmit = async () => {
    //(selectedFeedback);

    const headers = {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      "Content-Type": "application/json",
    };

    const body = {
      confirmation_code: confirmationCode,
      message: notes,
    };

    //(body);

    axios
      .post(`${process.env.REACT_APP_API_URL}note`, body, {
        headers,
      })
      .then((response) => {
        alert("Note Submitted.");
        setNotes("");
        data.data.check(true);
      })
      .catch((error) => {});
  };
  const [showTravelPlan, setShowTravelPlan] = useState(false);
  const [showTraveller, setShowTraveller] = useState(false);
  const [showMatch, setShowMatch] = useState(false);
  const [showPayment, setShowPayment] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [showFeedback, setShowFeedback] = useState(false);
  const [showTransitStatus, setShowTransitStatus] = useState(false);
  const [showCustomer, setShowCustomer] = useState(true);
  const [showticket, setShowticket] = useState(false);
  const [showoffering, setShowoffering] = useState(false);
  const [showPayout, setShowPayout] = useState(false);
  const [showNotes, setShowNotes] = useState(false);
  const [activeTab, setActiveTab] = useState("Customer");
  const [notes, setNotes] = useState("");
  const [refundType, setRefundType] = useState("full");

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
    switch (tabName) {
      case "TravelPlan":
        handleShowTravelPlan();
        break;
      case "Traveller":
        handleShowTraveller();
        break;
      case "Matches":
        handleShowMatch();
        break;
      case "Payment":
        handleShowPayment();
        break;
      case "Notification":
        handleShowNotification();
        break;
      case "Feedback":
        handleShowFeedback();
        break;
      case "TransitStatus":
        handleShowTransitStatus();
        break;
      case "Customer":
        handleShowCustomer();
        break;
      case "Ticket":
        handleShowTicket();
        break;
      case "Offering":
        handleShowOffering();
        break;
      case "Payout":
        handleShowPayout();
        break;
      case "Notes":
        handleShowNotes();
      default:
        break;
    }
  };
  const handleShowNotes = () => {
    setShowCustomer(false);
    setShowTravelPlan(false);
    setShowTraveller(false);
    setShowMatch(false);
    setShowFeedback(false);
    setShowNotification(false);
    setShowPayment(false);
    setShowTransitStatus(false);
    setShowticket(false);
    setShowoffering(false);
    setShowPayout(false);
    setShowNotes(true);
  };
  const handleShowPayout = () => {
    setShowCustomer(false);
    setShowTravelPlan(false);
    setShowTraveller(false);
    setShowMatch(false);
    setShowFeedback(false);
    setShowNotification(false);
    setShowPayment(false);
    setShowTransitStatus(false);
    setShowticket(false);
    setShowoffering(false);
    setShowPayout(true);
    setShowNotes(false);
  };
  const handleShowOffering = () => {
    setShowCustomer(false);
    setShowTravelPlan(false);
    setShowTraveller(false);
    setShowMatch(false);
    setShowFeedback(false);
    setShowNotification(false);
    setShowPayment(false);
    setShowTransitStatus(false);
    setShowticket(false);
    setShowoffering(true);
    setShowPayout(false);
    setShowNotes(false);
  };
  const handleShowTicket = () => {
    setShowCustomer(false);
    setShowTravelPlan(false);
    setShowTraveller(false);
    setShowMatch(false);
    setShowFeedback(false);
    setShowNotification(false);
    setShowPayment(false);
    setShowTransitStatus(false);
    setShowticket(true);
    setShowoffering(false);
    setShowNotes(false);
    setShowPayout(false);
  };
  const handleShowTravelPlan = () => {
    setShowCustomer(false);
    setShowTravelPlan(true);
    setShowTraveller(false);
    setShowMatch(false);
    setShowFeedback(false);
    setShowNotification(false);
    setShowPayment(false);
    setShowTransitStatus(false);
    setShowticket(false);
    setShowoffering(false);
    setShowPayout(false);
    setShowNotes(false);
  };

  const handleShowTraveller = () => {
    setShowTraveller(true);
    setShowTravelPlan(false);
    setShowMatch(false);
    setShowFeedback(false);
    setShowNotification(false);
    setShowPayment(false);
    setShowTransitStatus(false);
    setShowCustomer(false);
    setShowticket(false);
    setShowoffering(false);
    setShowPayout(false);
    setShowNotes(false);
  };
  const handleShowMatch = () => {
    setShowMatch(true);
    setShowTravelPlan(false);
    setShowTraveller(false);
    setShowFeedback(false);
    setShowNotification(false);
    setShowPayment(false);
    setShowTransitStatus(false);
    setShowCustomer(false);
    setShowticket(false);
    setShowoffering(false);
    setShowPayout(false);
    setShowNotes(false);
  };

  const handleShowPayment = () => {
    setShowPayment(true);
    setShowTravelPlan(false);
    setShowTraveller(false);
    setShowMatch(false);
    setShowFeedback(false);
    setShowNotification(false);
    setShowTransitStatus(false);
    setShowCustomer(false);
    setShowticket(false);
    setShowoffering(false);
    setShowPayout(false);
    setShowNotes(false);
  };

  const handleShowNotification = () => {
    setShowNotification(true);
    setShowTravelPlan(false);
    setShowTraveller(false);
    setShowMatch(false);
    setShowFeedback(false);
    setShowPayment(false);
    setShowTransitStatus(false);
    setShowCustomer(false);
    setShowticket(false);
    setShowoffering(false);
    setShowPayout(false);
    setShowNotes(false);
  };

  const handleShowFeedback = () => {
    setShowFeedback(true);
    setShowTravelPlan(false);
    setShowTraveller(false);
    setShowMatch(false);
    setShowNotification(false);
    setShowPayment(false);
    setShowTransitStatus(false);
    setShowCustomer(false);
    setShowticket(false);
    setShowoffering(false);
    setShowPayout(false);
    setShowNotes(false);
  };

  const handleShowTransitStatus = () => {
    setShowTransitStatus(true);
    setShowTravelPlan(false);
    setShowTraveller(false);
    setShowMatch(false);
    setShowNotification(false);
    setShowFeedback(false);
    setShowPayment(false);
    setShowCustomer(false);
    setShowticket(false);
    setShowoffering(false);
    setShowPayout(false);
    setShowNotes(false);
  };

  const handleShowCustomer = () => {
    setShowCustomer(true);
    setShowTravelPlan(false);
    setShowTraveller(false);
    setShowMatch(false);
    setShowNotification(false);
    setShowFeedback(false);
    setShowPayment(false);
    setShowTransitStatus(false);
    setShowticket(false);
    setShowoffering(false);
    setShowPayout(false);
    setShowNotes(false);
  };
  const handleAction = async (id, status, remarks = null) => {
    const headers = {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      "Content-Type": "application/json",
    };

    try {
      // Ensure remarks are provided if status is "REJECTED"
      if (status === "REJECTED" && (!remarks || remarks.trim() === "")) {
        alert("Remarks are required for rejecting a ticket.");
        return;
      }

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}ticket/update`,
        {
          id,
          status,
          remarks, // Include remarks in the payload
        },
        {
          headers,
        }
      );

      // Handle success response
      alert("Action Successful");
      data.data.check(true); // Refresh the data
    } catch (error) {
      alert(
        error.response?.data?.message || "An error occurred. Please try again."
      );
    }
  };
  const handleReject = (ticketId) => {
    setCurrentTicketId(ticketId);
    setShowRemarksModal(true);
  };

  const submitRejection = () => {
    if (remarks.trim() === "") {
      alert("Remarks are required for rejecting a ticket.");
      return;
    }
    handleAction(currentTicketId, "REJECTED", remarks);
    setShowRemarksModal(false);
    setRemarks("");
  };
  const handleRemarksClose = () => {
    setShowRemarksModal(false);
    setRemarks("");
  };
  const maskNumber = (number) => {
    return number.replace(/\d(?=\d{4})/g, "x");
  };
  const [isBankDetailsModalVisible, setBankDetailsModalVisibility] =
    useState(false);

  const openBankDetailsModal = () => {
    setBankDetailsModalVisibility(true);
  };

  const closeBankDetailsModal = () => {
    setBankDetailsModalVisibility(false);
  };
  const [isEditing, setIsEditing] = useState(false);
  const [editingNoteId, setEditingNoteId] = useState(null);
  const handleEditClick = (note) => {
    setIsEditing(true);

    setEditingNoteId(note.admin_crm_notes_id);
    setNotes(note.message);
  };
  const handleUpdateSubmit = async () => {
    if (!notes.trim()) {
      alert("Note cannot be empty!");
      return;
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}update-note`,
        {
          note_id: editingNoteId,
          message: notes,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      alert(response.data.message || "Note updated successfully!");
      setIsEditing(false);
      setEditingNoteId(null);
      setNotes("");
      data.data.check(true);
    } catch (error) {
      console.error("Error updating note:", error);
      alert("Failed to update the note.");
    }
  };
  const handleDeleteClick = async (noteId) => {
    if (!window.confirm(`Are you sure you want to delete this note?`)) return;

    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_API_URL}delete-note/${noteId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      alert(response.data.message || "Note deleted successfully!");
      data.data.check(true);
    } catch (error) {
      console.error("Error deleting note:", error);
      alert("Failed to delete the note.");
    }
  };

  return (
    <>
      {finalData && (
        <>
          <div className="container mt-4 ml-4">
            <div className="m-2 row align-items-center">
              <span className="col-auto fw-bold text-muted">
                Paid Companion:
              </span>
              <span
                className={`col-auto badge ${
                  TravelPlan[0]?.paid_companion ? "bg-success" : "bg-secondary"
                }`}
              >
                {TravelPlan[0]?.paid_companion ? "Yes" : "No"}
              </span>
            </div>
            <ul className="nav nav-tabs">
              <li className="nav-item" style={{ cursor: "pointer" }}>
                <a
                  className={`nav-link ${
                    activeTab === "Customer" ? "active" : ""
                  }`}
                  onClick={() => handleTabClick("Customer")}
                >
                  Customer
                </a>
              </li>
              <li className="nav-item" style={{ cursor: "pointer" }}>
                <a
                  className={`nav-link ${
                    activeTab === "TravelPlan" ? "active" : ""
                  }`}
                  onClick={() => handleTabClick("TravelPlan")}
                >
                  Travel Plan
                </a>
              </li>
              <li className="nav-item" style={{ cursor: "pointer" }}>
                <a
                  className={`nav-link ${
                    activeTab === "Traveller" ? "active" : ""
                  }`}
                  onClick={() => handleTabClick("Traveller")}
                >
                  Traveller
                </a>
              </li>
              {/* Add similar blocks for other tabs */}
              <li className="nav-item" style={{ cursor: "pointer" }}>
                <a
                  className={`nav-link ${
                    activeTab === "Matches" ? "active" : ""
                  }`}
                  onClick={() => handleTabClick("Matches")}
                >
                  Matches
                </a>
              </li>
              <li className="nav-item" style={{ cursor: "pointer" }}>
                <a
                  className={`nav-link ${
                    activeTab === "Payment" ? "active" : ""
                  }`}
                  onClick={() => handleTabClick("Payment")}
                >
                  Payment
                </a>
              </li>
              <li className="nav-item" style={{ cursor: "pointer" }}>
                <a
                  className={`nav-link ${
                    activeTab === "Ticket" ? "active" : ""
                  }`}
                  onClick={() => handleTabClick("Ticket")}
                >
                  Ticket
                </a>
              </li>
              <li className="nav-item" style={{ cursor: "pointer" }}>
                <a
                  className={`nav-link ${
                    activeTab === "Offering" ? "active" : ""
                  }`}
                  onClick={() => handleTabClick("Offering")}
                >
                  Offering
                </a>
              </li>
              <li className="nav-item" style={{ cursor: "pointer" }}>
                <a
                  className={`nav-link ${
                    activeTab === "Payout" ? "active" : ""
                  }`}
                  onClick={() => handleTabClick("Payout")}
                >
                  Payout
                </a>
              </li>
              <li className="nav-item" style={{ cursor: "pointer" }}>
                <a
                  className={`nav-link ${
                    activeTab === "TransitStatus" ? "active" : ""
                  }`}
                  onClick={() => handleTabClick("TransitStatus")}
                >
                  Transit Status
                </a>
              </li>
              <li className="nav-item" style={{ cursor: "pointer" }}>
                <a
                  className={`nav-link ${
                    activeTab === "Notification" ? "active" : ""
                  }`}
                  onClick={() => handleTabClick("Notification")}
                >
                  Notification
                </a>
              </li>
              <li className="nav-item" style={{ cursor: "pointer" }}>
                <a
                  className={`nav-link ${
                    activeTab === "Feedback" ? "active" : ""
                  }`}
                  onClick={() => handleTabClick("Feedback")}
                >
                  Feedback
                </a>
              </li>
            </ul>
            <div className="container mt-3">
              <div className="card bg-light">
                <div className="card-body">
                  {showCustomer && (
                    <div className="container mt-3">
                      <div className="card bg-light">
                        <div className="card-body">
                          {Array.isArray(customer) && customer.length > 0 ? (
                            <>
                              <table className="table table-striped">
                                <tbody>
                                  <tr>
                                    <th>Photo</th>
                                    <td>
                                      <img
                                        src={`${process.env.REACT_APP_IMAGE_URL}${customer[0].profile_photo_name}`}
                                        alt="No Photo"
                                        style={{
                                          width: "120px",
                                          height: "120px",
                                          borderRadius: "8px",
                                        }}
                                      />
                                    </td>
                                  </tr>

                                  <tr>
                                    <th>Name</th>
                                    <td>
                                      {customer[0]?.first_name}{" "}
                                      {customer[0]?.last_name}
                                    </td>
                                  </tr>
                                  <tr>
                                    <th>Gender</th>
                                    <td>
                                      {customer[0]?.gender === "M"
                                        ? "Male"
                                        : "Female"}
                                    </td>
                                  </tr>
                                  <tr>
                                    <th>Phone Number</th>
                                    <td>
                                      {customer[0]?.country_phone_code}{" "}
                                      {customer[0]?.phone_number}
                                    </td>
                                  </tr>
                                  <tr>
                                    <th>Email Address</th>
                                    <td>{customer[0]?.email_address}</td>
                                  </tr>
                                  <tr>
                                    <th>Corporate Email Address</th>
                                    <td>
                                      {customer[0]?.corporate_email_address}
                                    </td>
                                  </tr>
                                  <tr>
                                    <th>Age Range</th>
                                    <td>{customer[0]?.age_range_name}</td>
                                  </tr>
                                  <tr>
                                    <th>Primary Language</th>
                                    <td>
                                      {customer[0]?.primary_language_name}
                                    </td>
                                  </tr>
                                  <tr>
                                    <th>Secondary Language</th>
                                    <td>
                                      {customer[0]?.secondary_language_name}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                              {companionOnboarding?.profile === null && (
                                <div className="mt-2 d-flex justify-content-end">
                                  <button
                                    className="btn btn-success btn-sm me-2"
                                    onClick={() =>
                                      handleActionCustomer(
                                        TravelPlan[0]?.booking_id,
                                        "APPROVE"
                                      )
                                    }
                                  >
                                    APPROVE
                                  </button>
                                  <button
                                    className="btn btn-danger btn-sm"
                                    onClick={() =>
                                      handleRejectCustomer(
                                        TravelPlan[0]?.booking_id
                                      )
                                    }
                                  >
                                    REJECT
                                  </button>
                                </div>
                              )}
                            </>
                          ) : (
                            <div className="text-center mt-3">
                              <p>No customer information available.</p>
                            </div>
                          )}
                        </div>

                        <div className="card-body overflow-auto">
                          <div className="mb-3">
                            <label
                              htmlFor="feedbackMessage"
                              className="form-label"
                            >
                              {isEditing ? "Edit Note" : "Add Note"}
                            </label>
                            <textarea
                              id="feedbackMessage"
                              className="form-control"
                              rows="3"
                              onChange={(e) => setNotes(e.target.value)}
                              value={notes}
                            ></textarea>
                          </div>
                          <button
                            type="submit"
                            className="btn btn-primary"
                            onClick={
                              isEditing ? handleUpdateSubmit : handleNotesSubmit
                            }
                          >
                            {isEditing ? "Update" : "Submit"}
                          </button>
                          {Array.isArray(Notes) && Notes.length > 0 ? (
                            <table className="table table-striped">
                              <thead>
                                <tr>
                                  <th>S.No.</th>
                                  <th>Note</th>
                                  <th>Created</th>
                                  <th>Actions</th>
                                </tr>
                              </thead>
                              <tbody>
                                {Notes.map((feed, index) => (
                                  <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>{feed.message}</td>
                                    <td>
                                      {new Date(feed.created_at).toLocaleString(
                                        "en-US",
                                        {
                                          dateStyle: "medium",
                                          timeStyle: "medium",
                                        }
                                      )}
                                    </td>
                                    <td>
                                      <button
                                        className="btn btn-sm btn-warning me-2"
                                        onClick={() => handleEditClick(feed)}
                                      >
                                        Edit
                                      </button>
                                      <button
                                        className="btn btn-sm btn-danger"
                                        onClick={() =>
                                          handleDeleteClick(
                                            feed.admin_crm_notes_id
                                          )
                                        }
                                      >
                                        Delete
                                      </button>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          ) : (
                            <div className="text-center mt-3">
                              <p>No Notes available.</p>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  )}

                  {showTravelPlan && (
                    <div className="container mt-3">
                      <div className="card bg-light">
                        <div className="card-body overflow-auto">
                          {Array.isArray(TravelPlan) &&
                          TravelPlan.length > 0 ? (
                            <>
                              <table className="table table-striped overflow-auto">
                                <thead>
                                  <tr>
                                    {TravelPlan[0]?.date_of_travel && (
                                      <th>Date of Boarding</th>
                                    )}
                                    {TravelPlan[0]?.start_date_of_travel && (
                                      <>
                                        <th>Start Date of Travel</th>
                                        <th>End Date of Travel</th>
                                      </>
                                    )}
                                    <th>Airline Name</th>
                                    <th>Flight Code</th>
                                    <th>From</th>
                                    <th>To</th>
                                    <th>Ticket Booked</th>
                                    <th>Travel Plan Status</th>
                                    <th>Companionship Fee</th>
                                    <th>Active</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {TravelPlan.map((travel, index) => (
                                    <tr key={index}>
                                      {travel.date_of_travel && (
                                        <td>{travel.date_of_travel}</td>
                                      )}
                                      {travel.start_date_of_travel && (
                                        <>
                                          <td>{travel.start_date_of_travel}</td>
                                          <td>{travel.end_date_of_travel}</td>
                                        </>
                                      )}
                                      <td>{travel.airline_name}</td>
                                      <td>{travel.flight_code}</td>
                                      <td>
                                        ({travel.from_airport_iata_code}){" "}
                                        {travel.from_airport_name},{" "}
                                        {travel.fromCity}
                                      </td>
                                      <td>
                                        ({travel.to_airport_iata_code}){" "}
                                        {travel.to_airport_name},{" "}
                                        {travel.toCity}
                                      </td>
                                      <td>
                                        {travel.ticket_booked ? "Yes" : "No"}
                                      </td>
                                      <td>
                                        {travel.travel_plan_status ===
                                        "MATCH_FOUND"
                                          ? "Match Found"
                                          : "Created"}
                                      </td>
                                      <td>
                                        {TravelPlan[0]?.companionship_fee
                                          ? TravelPlan[0]?.companionship_fee
                                          : 0}
                                      </td>
                                      <td>{travel.active ? "Yes" : "No"}</td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>

                              {companionOnboarding?.earnings === null && (
                                <div className="mt-2 d-flex justify-content-end">
                                  {companionOnboarding?.profile === 1 &&
                                    companionOnboarding?.ticket === 1 &&
                                    companionOnboarding?.offerings === 1 &&
                                    companionOnboarding?.payout === 1 && (
                                      <button
                                        className="btn btn-success btn-sm me-2"
                                        onClick={() =>
                                          handleActionEarnings(
                                            TravelPlan[0].booking_id,
                                            "APPROVE"
                                          )
                                        }
                                      >
                                        APPROVED
                                      </button>
                                    )}
                                  <button
                                    className="btn btn-danger btn-sm"
                                    onClick={() =>
                                      handleRejectEarnings(
                                        TravelPlan[0].booking_id
                                      )
                                    }
                                  >
                                    REJECT
                                  </button>
                                </div>
                              )}
                            </>
                          ) : (
                            <div className="text-center mt-3">
                              <p>No travel plans available.</p>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  )}

                  {showTraveller && (
                    <div className="container mt-3">
                      <div className="card bg-light">
                        <div className="card-body overflow-auto">
                          {Array.isArray(filteredTravellers) &&
                          filteredTravellers.length > 0 ? (
                            <table className="table table-striped">
                              <thead>
                                <tr>
                                  <th>Name</th>
                                  <th>Age</th>
                                  <th>Gender</th>
                                  <th>Primary Language</th>
                                  <th>Secondary Language</th>
                                </tr>
                              </thead>
                              <tbody>
                                {filteredTravellers.map((data, index) => (
                                  <tr key={index}>
                                    <td>
                                      {data.first_name} {data.last_name}
                                    </td>
                                    <td>{data.age_range_name}</td>
                                    <td>
                                      {data.gender === "M" ? "Male" : "Female"}
                                    </td>
                                    <td>{data.primaryLanguage}</td>
                                    <td>{data.secondaryLanguage}</td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          ) : (
                            <div className="text-center mt-3">
                              <p>No travelers available.</p>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  )}

                  {showMatch && (
                    <div className="container mt-3">
                      <div className="card bg-light">
                        <div className="card-body overflow-auto">
                          {Matches && Matches.length > 0 ? (
                            <table className="table table-striped">
                              <thead>
                                <tr>
                                  <th>Name</th>
                                  <th>Confirmation Code</th>
                                  <th>Match Type</th>
                                  <th>Phone Number</th>
                                  <th>Match Status</th>
                                  <th>Revealed</th>
                                  <th>Flight Details</th>
                                </tr>
                              </thead>
                              <tbody>
                                {Matches.map((flight, index) => (
                                  <tr key={index}>
                                    <td>
                                      {flight?.party_b_first_name}{" "}
                                      {flight?.party_b_last_name}
                                    </td>
                                    <td>{flight?.party_b_confirmation_code}</td>
                                    <td>{flight?.match_type}</td>
                                    <td>
                                      {flight?.party_b_country_phone_code}{" "}
                                      {flight?.party_b_phone_number}
                                    </td>
                                    <td>{flight.match_status}</td>
                                    <td>{flight.revealed ? "Yes" : "No"}</td>
                                    <td>
                                      From: {flight.from_city_name},{" "}
                                      {flight.from_airport_name}
                                      <br />
                                      To: {flight.to_city_name},{" "}
                                      {flight.to_airport_name}
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          ) : (
                            <div className="text-center mt-3">
                              <p>No matches found.</p>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  )}

                  {showPayment && (
                    <div className="container mt-3">
                      <div className="card bg-light">
                        <div className="card-body overflow-auto">
                          {payment.length >= 1 ? (
                            <table className="table table-striped">
                              <thead>
                                <tr>
                                  <th>Amount(USD)</th>
                                  <th>Status</th>
                                  <th>Refunded Amount</th>
                                  <th>Source Type</th>
                                  <th>Note</th>
                                  <th>Payment Initiation DateTime</th>
                                  <th>Receipt Number</th>
                                  <th>Refund DateTime</th>
                                  <th>Payment Completion DateTime</th>
                                </tr>
                              </thead>
                              <tbody>
                                {payment.map((data, index) => (
                                  <tr key={index}>
                                    <td>{data.total_amount}</td>
                                    <td>{data.payment_status}</td>
                                    <td>
                                      {data.refunded_amount === null
                                        ? 0
                                        : data.refunded_amount}
                                    </td>
                                    <td>{data.source_type}</td>
                                    <td>{data.note}</td>
                                    <td>
                                      {new Date(
                                        data.payment_initiation_date_time
                                      ).toLocaleString("en-US", {
                                        dateStyle: "medium",
                                        timeStyle: "medium",
                                      })}
                                    </td>
                                    <td>{data.receipt_number}</td>
                                    <td>
                                      {data.refund_date_time === null
                                        ? "-"
                                        : new Date(
                                            data.refund_date_time
                                          ).toLocaleString("en-US", {
                                            dateStyle: "medium",
                                            timeStyle: "medium",
                                          })}
                                    </td>
                                    {data.payment_completion_date_time ===
                                    null ? (
                                      <td> -</td>
                                    ) : (
                                      <td>
                                        {new Date(
                                          data.payment_completion_date_time
                                        ).toLocaleString("en-US", {
                                          dateStyle: "medium",
                                          timeStyle: "medium",
                                        })}
                                      </td>
                                    )}
                                    {data.payment_status !==
                                      "Full Refunded" && (
                                      <td>
                                        <button
                                          className="refund-button"
                                          onClick={() =>
                                            handleRefund(
                                              data.total_amount,
                                              data.currency,
                                              data.payment_intent_id,
                                              data.refunded_amount
                                            )
                                          }
                                        >
                                          Refund
                                        </button>
                                      </td>
                                    )}
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          ) : (
                            <div className="text-center mt-3">
                              <p>No payment found.</p>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  )}

                  {showNotification && (
                    <div>
                      <div className="container mt-3">
                        <div className="btn-group" role="group">
                          {appNotifications?.length > 0 && (
                            <button
                              className={`btn ${
                                selectedMessageType === "APP"
                                  ? "btn-primary"
                                  : "btn-secondary"
                              }`}
                              onClick={() => setSelectedMessageType("APP")}
                            >
                              App
                            </button>
                          )}
                          <button
                            className={`btn ${
                              selectedMessageType === "SMS"
                                ? "btn-primary"
                                : "btn-secondary"
                            }`}
                            onClick={() => setSelectedMessageType("SMS")}
                          >
                            SMS
                          </button>
                          <button
                            className={`btn ${
                              selectedMessageType === "EMAIL"
                                ? "btn-primary"
                                : "btn-secondary"
                            }`}
                            onClick={() => setSelectedMessageType("EMAIL")}
                          >
                            Email
                          </button>
                          <button
                            className={`btn ${
                              selectedMessageType === "WEB"
                                ? "btn-primary"
                                : "btn-secondary"
                            }`}
                            onClick={() => setSelectedMessageType("WEB")}
                          >
                            Web
                          </button>
                        </div>
                      </div>

                      {selectedMessageType === "APP" && (
                        <div className="container mt-3">
                          <div className="card bg-light">
                            <div className="card-body overflow-auto">
                              {appNotifications?.length > 0 ? (
                                <>
                                  <table className="table table-striped">
                                    <thead>
                                      <tr>
                                        <th>Message Type</th>
                                        <th>Message</th>
                                        <th>Sent Timestamp</th>
                                        <th>Confirmation Code</th>
                                        <th>Notification Category</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {appNotifications.map((notif, index) => (
                                        <tr key={index}>
                                          <td>{notif.message_type}</td>
                                          <td>{notif.message}</td>
                                          <td>
                                            {new Date(
                                              notif.sent_timestamp
                                            ).toLocaleString("en-US", {
                                              dateStyle: "medium",
                                              timeStyle: "medium",
                                            })}
                                          </td>
                                          <td>{notif.confirmation_code}</td>
                                          <td>{notif.notification_category}</td>
                                        </tr>
                                      ))}
                                    </tbody>
                                  </table>
                                </>
                              ) : (
                                <div className="text-center mt-3">
                                  <p>No app notifications available.</p>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      )}

                      {selectedMessageType === "SMS" && (
                        <div className="container mt-3">
                          <div className="card bg-light">
                            <div className="card-body overflow-auto">
                              {smsNotifications?.length > 0 ? (
                                <>
                                  <table className="table table-striped">
                                    <thead>
                                      <tr>
                                        <th>Message Type</th>
                                        <th>Message</th>
                                        <th>Sent Timestamp</th>
                                        <th>Confirmation Code</th>
                                        <th>Notification Category</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {smsNotifications.map((notif, index) => (
                                        <tr key={index}>
                                          <td>{notif.message_type}</td>
                                          <td>{notif.message}</td>
                                          <td>
                                            {new Date(
                                              notif.sent_timestamp
                                            ).toLocaleString("en-US", {
                                              dateStyle: "medium",
                                              timeStyle: "medium",
                                            })}
                                          </td>
                                          <td>{notif.confirmation_code}</td>
                                          <td>{notif.notification_category}</td>
                                        </tr>
                                      ))}
                                    </tbody>
                                  </table>
                                </>
                              ) : (
                                <div className="text-center mt-3">
                                  <p>No SMS notifications available.</p>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      )}

                      {selectedMessageType === "EMAIL" && (
                        <div className="container mt-3">
                          <div className="card bg-light">
                            <div className="card-body overflow-auto">
                              {emailNotifications?.length > 0 ? (
                                <>
                                  <table className="table table-striped">
                                    <thead>
                                      <tr>
                                        <th>Message Type</th>
                                        <th>Message</th>
                                        <th>Sent Timestamp</th>
                                        <th>Confirmation Code</th>
                                        <th>Notification Category</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {emailNotifications.map(
                                        (notif, index) => (
                                          <tr key={index}>
                                            <td>{notif.message_type}</td>
                                            <td>{notif.message}</td>
                                            <td>
                                              {new Date(
                                                notif.sent_timestamp
                                              ).toLocaleString("en-US", {
                                                dateStyle: "medium",
                                                timeStyle: "medium",
                                              })}
                                            </td>
                                            <td>{notif.confirmation_code}</td>
                                            <td>
                                              {notif.notification_category}
                                            </td>
                                          </tr>
                                        )
                                      )}
                                    </tbody>
                                  </table>
                                </>
                              ) : (
                                <div className="text-center mt-3">
                                  <p>No email notifications available.</p>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      )}

                      {selectedMessageType === "WEB" && (
                        <div className="container mt-3">
                          <div className="card bg-light">
                            <div className="card-body overflow-auto">
                              {webNotifications?.length > 0 ? (
                                <>
                                  <h4 className="card-text mt-4 mb-2">
                                    Notifications
                                  </h4>
                                  <table className="table table-striped">
                                    <thead>
                                      <tr>
                                        <th>Message Type</th>
                                        <th>Message</th>
                                        <th>Sent Timestamp</th>
                                        <th>Confirmation Code</th>
                                        <th>Notification Category</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {webNotifications.map((notif, index) => (
                                        <tr key={index}>
                                          <td>{notif.message_type}</td>
                                          <td>{notif.message}</td>
                                          <td>
                                            {new Date(
                                              notif.sent_timestamp
                                            ).toLocaleString("en-US", {
                                              dateStyle: "medium",
                                              timeStyle: "medium",
                                            })}
                                          </td>
                                          <td>{notif.confirmation_code}</td>
                                          <td>{notif.notification_category}</td>
                                        </tr>
                                      ))}
                                    </tbody>
                                  </table>
                                </>
                              ) : (
                                <div className="text-center mt-3">
                                  <p>No web notifications available.</p>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  )}

                  {showFeedback && (
                    <div className="container mt-3">
                      <div className="card bg-light">
                        <div className="card-body overflow-auto">
                          {feedback?.length > 0 ? (
                            <table className="table table-striped">
                              <thead>
                                <tr>
                                  <th>Feedback Type</th>
                                  <th>Feedback</th>
                                  <th>Phone</th>
                                  <th>Email</th>
                                  <th>Country</th>
                                  <th>Feedback Timestamp</th>
                                </tr>
                              </thead>
                              <tbody>
                                {feedback.map((feed, index) => (
                                  <tr key={index}>
                                    <td>{feed.subject}</td>
                                    <td>{feed.message}</td>
                                    <td>{feed.phone_number}</td>
                                    <td>{feed.email}</td>
                                    <td>{feed.country_name}</td>
                                    <td>
                                      {new Date(feed.created_at).toLocaleString(
                                        "en-US",
                                        {
                                          dateStyle: "medium",
                                          timeStyle: "medium",
                                        }
                                      )}
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          ) : (
                            <div className="text-center mt-3">
                              <p>No feedback available.</p>
                            </div>
                          )}

                          <div className="mt-4 mb-3">
                            <select
                              className="form-select"
                              id="feedbackSelect"
                              value={selectedFeedback}
                              onChange={handleFeedbackChange}
                            >
                              <option value="">Select Feedback</option>
                              {feedbackSubject?.map((feed, index) => (
                                <option
                                  key={index}
                                  value={feed.feedback_subject_id}
                                >
                                  {feed.subject}
                                </option>
                              ))}
                            </select>
                          </div>

                          <div className="mb-3">
                            <label
                              htmlFor="feedbackMessage"
                              className="form-label"
                            >
                              Feedback Message
                            </label>
                            <textarea
                              className="form-control"
                              rows="3"
                              disabled={!selectedFeedback}
                              onChange={(e) =>
                                setFeedbackMessage(e.target.value)
                              }
                              value={feedbackMessage}
                            ></textarea>
                          </div>
                          <button
                            type="submit"
                            className="btn btn-primary"
                            disabled={!selectedFeedback}
                            onClick={handleFeedbackSubmit}
                          >
                            Submit Feedback
                          </button>
                        </div>
                      </div>
                    </div>
                  )}

                  {showTransitStatus && (
                    <div className="container mt-3">
                      <div className="card bg-light">
                        <div className="card-body overflow-auto">
                          {transitStatus?.length > 0 ? (
                            <table className="table table-striped">
                              <thead>
                                <tr>
                                  <th>Transit Step</th>
                                  <th>Created Time</th>
                                  <th>Updated Time</th>
                                  <th>Order</th>
                                  <th>Step Completed</th>
                                </tr>
                              </thead>
                              <tbody>
                                {transitStatus.map((transit, index) => (
                                  <tr key={index}>
                                    <td>{transit.transit_code}</td>
                                    <td>
                                      {new Date(
                                        transit.created_time_stamp
                                      ).toLocaleString("en-US", {
                                        dateStyle: "medium",
                                        timeStyle: "medium",
                                      })}
                                    </td>
                                    <td>
                                      {new Date(
                                        transit.updated_time_stamp
                                      ).toLocaleString("en-US", {
                                        dateStyle: "medium",
                                        timeStyle: "medium",
                                      })}
                                    </td>
                                    <td>{transit.order}</td>
                                    <td>
                                      {transit.step_completed ? "Yes" : "No"}
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          ) : (
                            <div className="text-center mt-3">
                              <p>No transit status available.</p>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  )}

                  {showticket && (
                    <div className="container mt-3">
                      <div className="card bg-light">
                        <div className="card-body overflow-auto">
                          {Ticket?.length > 0 ? (
                            <table className="table table-striped">
                              <thead>
                                <tr>
                                  <th>S.No.</th>
                                  <th>Name</th>
                                  <th>View</th>
                                  <th>Remarks</th>
                                  <th>Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                {Ticket.map((ticket, index) => (
                                  <tr key={ticket.ticket_id}>
                                    <td>{index + 1}</td>
                                    <td>{ticket.file_name}</td>
                                    <td>
                                      <button
                                        className="btn btn-primary btn-sm"
                                        onClick={() =>
                                          window.open(
                                            ticket.ticket_url || "#",
                                            "_blank"
                                          )
                                        }
                                        disabled={!ticket.ticket_url}
                                      >
                                        View
                                      </button>
                                    </td>
                                    <td>{ticket.remarks}</td>
                                    <td>
                                      {ticket.status === "UPLOADED" ? (
                                        <>
                                          <button
                                            className="btn btn-success btn-sm me-2"
                                            onClick={() =>
                                              handleAction(
                                                ticket.ticket_id,
                                                "APPROVED"
                                              )
                                            }
                                          >
                                            APPROVE
                                          </button>
                                          <button
                                            className="btn btn-danger btn-sm"
                                            onClick={() =>
                                              handleReject(ticket.ticket_id)
                                            }
                                          >
                                            REJECT
                                          </button>
                                        </>
                                      ) : (
                                        <span className="badge bg-info text-success">
                                          {ticket.status}
                                        </span>
                                      )}
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          ) : (
                            <div className="text-center mt-3">
                              <p>No tickets available.</p>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  )}

                  {showoffering && (
                    <div className="container mt-3">
                      <div className="card bg-light">
                        <div className="card-body overflow-auto">
                          {Offering?.length > 0 ? (
                            <>
                              <table className="table table-striped">
                                <thead>
                                  <tr>
                                    <th>S.No.</th>
                                    <th>Offering Name</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {Offering.map((offering, index) => (
                                    <tr key={offering.companion_offering_id}>
                                      <td>{index + 1}</td>
                                      <td>{offering.offering_name}</td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>

                              {companionOnboarding?.offerings === null && (
                                <div className="mt-2 d-flex justify-content-end">
                                  <button
                                    className="btn btn-success btn-sm me-2"
                                    onClick={() =>
                                      handleActionOffering(
                                        Offering[0].booking_id,
                                        "APPROVE"
                                      )
                                    }
                                  >
                                    APPROVE
                                  </button>
                                  <button
                                    className="btn btn-danger btn-sm"
                                    onClick={() =>
                                      handleRejectOffering(
                                        Offering[0].booking_id
                                      )
                                    }
                                  >
                                    REJECT
                                  </button>
                                </div>
                              )}
                            </>
                          ) : (
                            <div className="text-center mt-3">
                              <p>No offerings available.</p>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  )}

                  {showPayout && (
                    <div className="container mt-3">
                      <div className="card bg-light">
                        <div className="card-body overflow-auto">
                          {payout[0].payout !== null ? (
                            <>
                              <table className="table table-striped">
                                <thead>
                                  <tr>
                                    <th>Payout</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr key={payout[0]?.confirmation_code}>
                                    <td>
                                      {payout[0]?.payout === "BANK" ? (
                                        <table className="table table-striped">
                                          <tbody>
                                            <tr>
                                              <th>Routing Number</th>
                                              <td>
                                                {payout[0].routing_number}
                                              </td>
                                            </tr>
                                            <tr>
                                              <th>Account Number</th>
                                              <td>
                                                {payout[0].account_number}
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      ) : (
                                        payout[0]?.payout
                                      )}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                              {Array.isArray(TravelPlan) &&
                                TravelPlan.length > 0 &&
                                companionOnboarding?.payout === null && (
                                  <div className="mt-2 d-flex justify-content-end">
                                    <button
                                      className="btn btn-success btn-sm me-2"
                                      onClick={() =>
                                        handleActionPayout(
                                          TravelPlan[0].booking_id,
                                          "APPROVE"
                                        )
                                      }
                                    >
                                      APPROVE
                                    </button>
                                    <button
                                      className="btn btn-danger btn-sm"
                                      onClick={() =>
                                        handleRejectPayout(
                                          TravelPlan[0].booking_id
                                        )
                                      }
                                    >
                                      REJECT
                                    </button>
                                  </div>
                                )}
                            </>
                          ) : (
                            <div className="text-center mt-3">
                              <p>No payout details available.</p>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <Modal show={showModal} onHide={handleCloseModal} centered>
              <Modal.Header closeButton>
                <Modal.Title>Refund Details</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="mb-3">
                  <label className="form-label">Refund Type</label>
                  {totalRefunded === null && (
                    <div>
                      <input
                        type="radio"
                        id="fullRefund"
                        name="refundType"
                        value="full"
                        checked={refundType === "full"}
                        onChange={() => {
                          setRefundType("full");
                        }}
                      />
                      <label htmlFor="fullRefund" className="ms-2">
                        Full Payment
                      </label>
                    </div>
                  )}
                  <div className="mt-2">
                    <input
                      type="radio"
                      id="partialRefund"
                      name="refundType"
                      value="partial"
                      checked={refundType === "partial"}
                      onChange={() => {
                        setRefundType("partial");
                        setRefundAmount(""); // Reset for partial amount input
                      }}
                    />
                    <label htmlFor="partialRefund" className="ms-2">
                      Partial Payment
                    </label>
                  </div>
                </div>
                {refundType === "partial" && (
                  <div className="mb-3">
                    <label htmlFor="refundAmount" className="form-label">
                      Refund upto {amount - totalRefunded} USD
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="refundAmount"
                      value={refundAmount}
                      onChange={(e) => setRefundAmount(e.target.value)}
                      disabled={refundType === "full"} // Disable for full refund
                    />
                  </div>
                )}
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleCloseModal}>
                  Close
                </Button>
                <Button variant="primary" onClick={submitRefund}>
                  Confirm
                </Button>
              </Modal.Footer>
            </Modal>

            <Modal show={showRemarksModal} centered>
              <Modal.Header>
                <Modal.Title>Provide Remarks</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="mb-3">
                  <label htmlFor="remarks" className="form-label">
                    Remarks
                  </label>
                  <textarea
                    id="remarks"
                    className="form-control"
                    rows="3"
                    value={remarks}
                    onChange={(e) => setRemarks(e.target.value)}
                    placeholder="Enter remarks for rejecting this ticket..."
                  ></textarea>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleRemarksClose}>
                  Close
                </Button>
                <Button variant="primary" onClick={submitRejection}>
                  Submit
                </Button>
              </Modal.Footer>
            </Modal>
            <Modal show={showRemarksModalOffering} centered>
              <Modal.Header>
                <Modal.Title>Provide Remarks</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="mb-3">
                  <label htmlFor="remarks" className="form-label">
                    Remarks
                  </label>
                  <textarea
                    id="remarks"
                    className="form-control"
                    rows="3"
                    value={remarksOffering}
                    onChange={(e) => setRemarksOffering(e.target.value)}
                    placeholder="Enter remarks for rejecting this offering..."
                  ></textarea>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant="secondary"
                  onClick={handleRemarksCloseOffering}
                >
                  Close
                </Button>
                <Button variant="primary" onClick={submitRejectionOffering}>
                  Submit
                </Button>
              </Modal.Footer>
            </Modal>
            <Modal show={showRemarksModalCustomer} centered>
              <Modal.Header>
                <Modal.Title>Provide Remarks</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="mb-3">
                  <label htmlFor="remarks" className="form-label">
                    Remarks
                  </label>
                  <textarea
                    id="remarks"
                    className="form-control"
                    rows="3"
                    value={remarksCustomer}
                    onChange={(e) => setRemarksCustomer(e.target.value)}
                    placeholder="Enter remarks for rejecting this customer..."
                  ></textarea>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant="secondary"
                  onClick={handleRemarksCloseCustomer}
                >
                  Close
                </Button>
                <Button variant="primary" onClick={submitRejectionCustomer}>
                  Submit
                </Button>
              </Modal.Footer>
            </Modal>
            <Modal show={showRemarksModalPayout} centered>
              <Modal.Header>
                <Modal.Title>Provide Remarks</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="mb-3">
                  <label htmlFor="remarksPayout" className="form-label">
                    Remarks
                  </label>
                  <textarea
                    id="remarksPayout"
                    className="form-control"
                    rows="3"
                    value={remarksPayout}
                    onChange={(e) => setRemarksPayout(e.target.value)}
                    placeholder="Enter remarks for rejecting this payout..."
                  ></textarea>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleRemarksClosePayout}>
                  Close
                </Button>
                <Button variant="primary" onClick={submitRejectionPayout}>
                  Submit
                </Button>
              </Modal.Footer>
            </Modal>
            <Modal show={showRemarksModalEarnings} centered>
              <Modal.Header>
                <Modal.Title>Provide Remarks</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="mb-3">
                  <label htmlFor="remarksEarnings" className="form-label">
                    Remarks
                  </label>
                  <textarea
                    id="remarksEarnings"
                    className="form-control"
                    rows="3"
                    value={remarksEarnings}
                    onChange={(e) => setRemarksEarnings(e.target.value)}
                    placeholder="Enter remarks for rejecting this earnings update..."
                  ></textarea>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant="secondary"
                  onClick={handleRemarksCloseEarnings}
                >
                  Close
                </Button>
                <Button variant="primary" onClick={submitRejectionEarnings}>
                  Submit
                </Button>
              </Modal.Footer>
            </Modal>
            <Modal
              show={isBankDetailsModalVisible}
              onHide={closeBankDetailsModal}
              centered
            >
              <Modal.Header closeButton>
                <Modal.Title>Bank Details</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="mb-3">
                  <label htmlFor="routingNumber" className="form-label">
                    Routing Number
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="routingNumber"
                    value={maskNumber(payout?.routing_number || "")}
                    readOnly
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="accountNumber" className="form-label">
                    Account Number
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="accountNumber"
                    value={maskNumber(payout?.account_number || "")}
                    readOnly
                  />
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={closeBankDetailsModal}>
                  Close
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
        </>
      )}
    </>
  );
};

export default FetchDetails;
