import React, { useState, useEffect } from "react";
import axios from "axios";
import { Table, Modal, Button, Spinner, Pagination } from "react-bootstrap";

const OfferingType = () => {
  const [offeringData, setOfferingData] = useState([]);
  const [areaOfoffering, setAreaOfoffering] = useState("");
  const [typeName, setTypeName] = useState("");
  const [active, setActive] = useState(false);
  const [checkUpdate, setCheckUpdate] = useState(null);
  const [areaOptions, setAreaOptions] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 10;

  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = offeringData?.slice(indexOfFirstRow, indexOfLastRow);

  const totalPages = Math.ceil(offeringData?.length / rowsPerPage);

  const fetchData = () => {
    setLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}offering_type`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        setOfferingData(response.data);
        setLoading(false);
      })
      .catch((error) => {
        alert("Something went wrong.");
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchData();
    axios
      .get(`${process.env.REACT_APP_API_URL}area_of_offering`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        setAreaOptions(response.data);
      })
      .catch((error) => {
        alert("Failed to load area options.");
      });
  }, [showModal]);

  const handleAddOffering = () => {
    if (!areaOfoffering || !typeName) {
      alert("Please fill in all required fields.");
      return;
    }

    const formData = {
      area_of_offering: areaOfoffering,
      type: typeName,
      active: active ? 1 : 0,
    };

    const request = checkUpdate
      ? axios.post(
          `${process.env.REACT_APP_API_URL}offering_type/${checkUpdate}`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        )
      : axios.post(`${process.env.REACT_APP_API_URL}offering_type`, formData, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });

    request
      .then(() => {
        fetchData();
        setShowModal(false);
        setAreaOfoffering("");
        setTypeName("");
        setActive(false);
        setCheckUpdate(null);
      })
      .catch((error) => {
        alert("Offering type already exists or an error occurred.");
      });
  };

  const handleUpdateOffering = (id) => {
    setShowModal(true);
    setCheckUpdate(id);
    axios
      .get(`${process.env.REACT_APP_API_URL}offering_type/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        const data = response.data[0];
        setAreaOfoffering(data.area_of_offering_id);
        setTypeName(data.type);
        setActive(data.active);
      })
      .catch((error) => {
        alert("Failed to load offering details.");
      });
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setAreaOfoffering("");
    setTypeName("");
    setActive(false);
    setCheckUpdate(null);
  };

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div>
      <div className="d-flex justify-content-between align-items-center mb-3">
        <h1>Offering Type</h1>
        <button
          className="btn btn-primary"
          style={{ marginRight: "1rem" }}
          onClick={() => setShowModal(true)}
        >
          ADD
        </button>
      </div>

      {loading ? (
        <div className="d-flex justify-content-center align-items-center">
          <Spinner animation="border text-primary" />
        </div>
      ) : (
        <>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>SNo.</th>
                <th>Area of Offering Code</th>
                <th>Description</th>
                <th>Type</th>
                <th>Active</th>
              </tr>
            </thead>
            <tbody>
              {currentRows?.map((offering, index) => (
                <tr key={offering.offering_type_id}>
                  <td>{indexOfFirstRow + index + 1}</td>
                  <td
                    onClick={() =>
                      handleUpdateOffering(offering.offering_type_id)
                    }
                    style={{
                      cursor: "pointer",
                      color: "blue",
                      fontWeight: "bold",
                      textDecoration: "underline",
                    }}
                  >
                    {offering.area_of_offering_code}
                  </td>
                  <td>{offering.description}</td>
                  <td>{offering.type}</td>
                  <td>{offering.active ? "Yes" : "No"}</td>
                </tr>
              ))}
            </tbody>
          </Table>
          <div className="d-flex justify-content-center">
            <Pagination>
              <Pagination.Prev
                disabled={currentPage === 1}
                onClick={() => paginate(currentPage - 1)}
              >
                Previous
              </Pagination.Prev>
              {Array.from({ length: totalPages }, (_, i) => i + 1).map(
                (number) => (
                  <Pagination.Item
                    key={number}
                    active={number === currentPage}
                    onClick={() => paginate(number)}
                  >
                    {number}
                  </Pagination.Item>
                )
              )}
              <Pagination.Next
                disabled={currentPage === totalPages}
                onClick={() => paginate(currentPage + 1)}
              >
                Next
              </Pagination.Next>
            </Pagination>
          </div>
        </>
      )}

      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>
            {checkUpdate ? "Edit Offering" : "Add Offering"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group">
            <label htmlFor="areaOfoffering">Area of offering</label>
            <select
              className="form-control"
              value={areaOfoffering}
              onChange={(e) => setAreaOfoffering(e.target.value)}
              required
            >
              <option value="">Select an area</option>
              {areaOptions.map((area) => (
                <option
                  key={area.area_of_offering_id}
                  value={area.area_of_offering_id}
                >
                  {area.area_of_offering_code}
                </option>
              ))}
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="type">Type</label>
            <input
              type="text"
              className="form-control"
              value={typeName}
              onChange={(e) => setTypeName(e.target.value)}
              placeholder="Enter offering type"
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="active">
              <input
                type="checkbox"
                checked={active}
                onChange={(e) => setActive(e.target.checked)}
              />{" "}
              Active
            </label>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
          <Button variant="primary" onClick={handleAddOffering}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default OfferingType;
