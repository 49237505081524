import React, { useState, useEffect } from "react";
import axios from "axios";
import { Table, Pagination, Modal, Button } from "react-bootstrap";
import { faSlash } from "@fortawesome/free-solid-svg-icons";

const Country = () => {
  const [flightInfoData, setFlightInfoData] = useState([]);
  const [fromAirport, setFromAirports] = useState("");
  const [toAirport, setToAirports] = useState("");
  const [airports, setAirports] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage] = useState(15);
  const [countryCode, setCountryCode] = useState("");
  const [countryName, setCountryName] = useState("");
  const [fromAirports, setFromAirport] = useState("");
  const [toAirports, setToAirport] = useState("");
  const [arrivalTime, setArrivalTime] = useState("");
  const [checkUpdate, setCheckUpdate] = useState(null);
  const [airlineOptions, setAirlineOptions] = useState();
  const [active, setActive] = useState(true);
  const [edit, setEdit] = useState(false);

  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = airports?.slice(indexOfFirstRow, indexOfLastRow);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => {
    setCountryCode("");
    setCountryName("");
    setFromAirports("");
    setToAirports("");

    setCheckUpdate(null);
    setEdit(false);
    setActive(false);
    setShowModal(false);
  };

  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}flightinformation`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        setFlightInfoData(response.data.data);
        //(response.data.data);
      })
      .catch((error) => {});
  }, [showModal]);

  const handleAddFlightInfo = () => {
    //(countryCode, countryName);
    if (!countryCode || !countryName) {
      alert("Please fill all the fields");
      return;
    }

    const formData = {
      country_code: countryCode,
      country_name: countryName,
      active: active ? 1 : 0,
      priority: "99",
    };

    if (checkUpdate !== null) {
      axios
        .post(
          `${process.env.REACT_APP_API_URL}country/${checkUpdate}`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        )
        .then((response) => {
          setFlightInfoData([...flightInfoData, response.data.data]);
          setCountryCode("");
          setCountryName("");
          setFromAirports("");
          setToAirports("");
          setEdit(false);
          setShowModal(false);
          setCheckUpdate(null);
        })
        .catch((error) => {});
    } else {
      axios
        .post(`${process.env.REACT_APP_API_URL}createCountry`, formData, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((response) => {
          setFlightInfoData([...flightInfoData, response.data.data]);
          setCountryCode("");
          setCountryName("");
          setFromAirport("");
          setToAirport("");
          setArrivalTime("");
          setCheckUpdate(null);
          setShowModal(false);
        })
        .catch((error) => {});
    }
  };

  const handleUpdateFlightInfo = (e) => {
    //(e);
    handleShowModal();
    setCheckUpdate(e);
    setEdit(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}country/${e}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        //(response.data);
        setCountryCode(response.data.data.country_code);
        setCountryName(response.data.data.country_name);
        setActive(response.data.data.active);
      })
      .catch((error) => {});
  };

  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(airports?.length / rowsPerPage); i++) {
    pageNumbers.push(i);
  }

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}allCountry`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        setAirports(response.data.data);

        //(response.data.data);
      })
      .catch((error) => {
        //(error);
      });
  }, [showModal]);

  return (
    <div>
      <div>
        <div className="d-flex justify-content-between align-items-center mb-3">
          <h1>Country</h1>
          <button
            className="btn btn-primary "
            style={{ marginRight: "1rem" }}
            onClick={handleShowModal}
          >
            ADD
          </button>
        </div>

        <Table striped bordered hover>
          <thead>
            <tr>
              <th>SNo.</th>
              <th>Country Code</th>
              <th>Country Name</th>
              <th>Active</th>
            </tr>
          </thead>
          <tbody>
            {currentRows?.map((flightInfo, index) => (
              <tr key={flightInfo?.country_id}>
                <td>{indexOfFirstRow + index + 1}</td>
                <td
                  onClick={() => handleUpdateFlightInfo(flightInfo?.country_id)}
                  style={{
                    cursor: "pointer",
                    color: "blue",
                    fontWeight: "bold",
                    textDecoration: "underline",
                  }}
                >
                  {flightInfo?.country_code}
                </td>
                <td>{flightInfo?.country_name}</td>
                <td>{flightInfo?.active == 1 ? "Yes" : "No"}</td>
              </tr>
            ))}
          </tbody>
        </Table>

        <div className="d-flex justify-content-center">
          <Pagination>
            {pageNumbers.map((number, index) => {
              if (
                index === 0 ||
                index === pageNumbers?.length - 1 ||
                Math.abs(currentPage - number) <= 1
              ) {
                return (
                  <Pagination.Item
                    key={number}
                    active={number === currentPage}
                    onClick={() => paginate(number)}
                  >
                    {number}
                  </Pagination.Item>
                );
              } else if (index === 1 || index === pageNumbers?.length - 2) {
                return (
                  <Pagination.Ellipsis
                    key={number}
                    onClick={() => paginate(number)}
                  />
                );
              } else {
                return null;
              }
            })}
          </Pagination>
        </div>

        <Modal show={showModal} onHide={handleCloseModal}>
          <Modal.Header closeButton>
            <Modal.Title>{edit ? "Edit Country" : "Add Country"}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form id="addFlightInfoForm">
              <div className="form-group">
                <label htmlFor="countryCode">Country Code</label>
                <input
                  type="text"
                  className="form-control"
                  onChange={(e) => setCountryCode(e.target.value)}
                  name="countryCode"
                  placeholder="Enter Country Code"
                  value={countryCode}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="countryCode">Country Name</label>
                <input
                  type="text"
                  className="form-control"
                  onChange={(e) => setCountryName(e.target.value)}
                  name="countryCode"
                  placeholder="Enter Country Name"
                  value={countryName}
                  required
                />
              </div>

              <div className="form-group">
                <div style={{ display: "flex" }}>
                  <label
                    htmlFor="active"
                    style={{
                      marginRight: "1rem",
                    }}
                  >
                    Active
                  </label>
                  <div>
                    <input
                      type="checkbox"
                      onChange={(e) => setActive(e.target.checked)}
                      name="active"
                      checked={active}
                    />
                  </div>
                </div>
              </div>
              <div className="form-group">
                <label htmlFor="priority">Priority</label>
                <input
                  type="text"
                  className="form-control"
                  name="priority"
                  placeholder="Enter priority"
                  disabled
                />
              </div>
            </form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseModal}>
              Close
            </Button>
            <Button variant="primary" onClick={handleAddFlightInfo}>
              Save Changes
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
};

export default Country;
