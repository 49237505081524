import React from "react";
import Sidebar from "../components/Sidebar";
import NavbarComponent from "../components/Navbar";
import RecentRequest from "../components/RecentRequest";

const RecentRequestPage = () => {
  return (
    <>
      <NavbarComponent />
      <div className="d-flex">
        <Sidebar />
        <div className="flex-grow-1">
          <RecentRequest />
        </div>
      </div>
    </>
  );
};

export default RecentRequestPage;
