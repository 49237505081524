import React, { useState, useEffect } from "react";
import axios from "axios";
import { Table, Pagination, Modal, Button } from "react-bootstrap";

const State = () => {
  const [states, setStates] = useState([]);
  const [countries, setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage] = useState(15);
  const [showModal, setShowModal] = useState(false);
  const [stateCode, setStateCode] = useState("");
  const [stateName, setStateName] = useState("");
  const [countryId, setCountryId] = useState("");
  const [checkUpdate, setCheckUpdate] = useState(null);

  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = states?.slice(indexOfFirstRow, indexOfLastRow);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => {
    setStateCode("");
    setStateName("");
    setCountryId("");
    setCheckUpdate(null);
    setShowModal(false);
  };

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}country`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        setCountries(response.data.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const fetchStatesByCountry = (countryId) => {
    axios
      .get(`${process.env.REACT_APP_API_URL}state/${countryId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        setStates(response.data.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleCountryChange = (event) => {
    const selectedCountryId = event.target.value;
    setSelectedCountry(selectedCountryId);

    if (selectedCountryId) {
      fetchStatesByCountry(selectedCountryId);
    } else {
      setStates([]);
    }
  };

  const handleAddOrUpdateState = () => {
    if (!stateCode || !stateName || !countryId) {
      alert("Please fill all the fields.");
      return;
    }

    const formData = {
      state_code: stateCode,
      state_name: stateName,
      country_id: countryId,
    };

    if (checkUpdate !== null) {
      // Update state
      axios
        .post(
          `${process.env.REACT_APP_API_URL}update-state/${checkUpdate}`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        )
        .then((response) => {
          handleCloseModal();
          fetchStatesByCountry(selectedCountry);
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      // Add state
      axios
        .post(`${process.env.REACT_APP_API_URL}state`, formData, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((response) => {
          handleCloseModal();
          fetchStatesByCountry(selectedCountry);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  const handleEditState = (state) => {
    setStateCode(state.state_code);
    setStateName(state.state_name);
    setCountryId(state.country_id);
    setCheckUpdate(state.state_id);
    handleShowModal();
  };

  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(states?.length / rowsPerPage); i++) {
    pageNumbers.push(i);
  }

  return (
    <div>
      <div className="d-flex justify-content-between align-items-center mb-3">
        <div>
          <h1>State</h1>
          <div className="d-flex align-items-center">
            fight add <h3 className="me-3">Select a Country:</h3>
            <select
              className="form-select"
              value={selectedCountry}
              onChange={handleCountryChange}
              style={{ width: "50%" }}
            >
              <option value="">Select a Country</option>
              {countries.map((country) => (
                <option key={country.country_id} value={country.country_id}>
                  {country.country_name}
                </option>
              ))}
            </select>
          </div>
        </div>
        <button className="btn btn-primary" onClick={handleShowModal}>
          Add State
        </button>
      </div>

      <Table striped bordered hover>
        <thead>
          <tr>
            <th>SNo.</th>
            <th>State Code</th>
            <th>State Name</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {currentRows?.map((state, index) => (
            <tr key={state.state_id}>
              <td>{indexOfFirstRow + index + 1}</td>
              <td>{state.state_code}</td>
              <td>{state.state_name}</td>
              <td>
                <button
                  className="btn btn-primary"
                  onClick={() => handleEditState(state)}
                >
                  Edit
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      <div className="d-flex justify-content-center">
        <Pagination>
          {pageNumbers.map((number) => (
            <Pagination.Item
              key={number}
              active={number === currentPage}
              onClick={() => paginate(number)}
            >
              {number}
            </Pagination.Item>
          ))}
        </Pagination>
      </div>

      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>{checkUpdate ? "Edit State" : "Add State"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group">
            <label>Country</label>
            <select
              className="form-select"
              value={countryId}
              onChange={(e) => setCountryId(e.target.value)}
            >
              <option value="">Select a Country</option>
              {countries.map((country) => (
                <option key={country.country_id} value={country.country_id}>
                  {country.country_name}
                </option>
              ))}
            </select>
          </div>
          <div className="form-group">
            <label>State Code</label>
            <input
              type="text"
              className="form-control"
              value={stateCode}
              onChange={(e) => setStateCode(e.target.value)}
              placeholder="Enter State Code"
            />
          </div>
          <div className="form-group">
            <label>State Name</label>
            <input
              type="text"
              className="form-control"
              value={stateName}
              onChange={(e) => setStateName(e.target.value)}
              placeholder="Enter State Name"
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
          <Button variant="primary" onClick={handleAddOrUpdateState}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default State;
