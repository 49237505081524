import React, { useEffect, useState } from "react";
import axios from "axios";

const User = () => {
  const [user, setUser] = useState([]);
  const [loading, setLoading] = useState(true); // State for loading indicator
  const [currentPage, setCurrentPage] = useState(1); // State for current page
  const [itemsPerPage] = useState(10); // Number of items per page

  // Fetch data when component mounts
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true); // Set loading to true when starting fetch
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}user`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        setUser(response.data);
      } catch (error) {
      } finally {
        setLoading(false); // Set loading to false once data is fetched or if error occurs
      }
    };

    fetchData();
  }, []);

  // Handle pagination
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = user.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(user.length / itemsPerPage);

  // Handle page change
  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  // Handle locked status change
  const handleChange = async (locked, user_id) => {
    const confirmChange = window.confirm(
      `Are you sure you want to ${locked ? "unlock" : "lock"} this user?`
    );
    if (confirmChange) {
      locked = locked === 1 ? 0 : 1;

      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}user/${user_id}/locked`,
          { locked },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );

        // Update the user state with the new locked status
        setUser((prevUser) => {
          const updatedUser = prevUser.map((item) => {
            if (item.user_id === user_id) {
              return {
                ...item,
                locked: locked,
              };
            }
            return item;
          });
          return updatedUser;
        });
      } catch (error) {
        alert("User locked status change failed");
      }
    } else {
      return;
    }
  };

  return (
    <div>
      <div className="container mt-3">
        <h2>Users</h2>
        <div className="card bg-light">
          <div className="card-body">
            <div className="container mt-3">
              <div className="card bg-light">
                <div className="card-body overflow-auto">
                  {/* Loading Spinner */}
                  {loading ? (
                    <div className="d-flex justify-content-center align-items-center">
                      <div
                        className="spinner-border text-primary"
                        role="status"
                      >
                        <span className="sr-only">Loading...</span>
                      </div>
                    </div>
                  ) : (
                    <table className="table table-striped overflow-auto">
                      <thead>
                        <tr>
                          <td>S.No.</td>
                          <th>Name</th>
                          <th>Phone Number</th>

                          <th>Lock</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {currentItems?.map((item, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{item?.name}</td>
                            <td>
                              {item?.customer.country_phone_code}{" "}
                              {item?.customer.phone_number}
                            </td>

                            <td>{item?.locked ? "Yes" : "No"}</td>
                            <td>
                              <button
                                onClick={() =>
                                  handleChange(item?.locked, item?.user_id)
                                }
                                className="btn btn-primary me-2"
                              >
                                {item?.locked ? "Unlock" : "Lock"}
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  )}

                  {/* Pagination controls */}
                  <div className="d-flex justify-content-center mt-3">
                    <button
                      className="btn btn-primary me-2"
                      onClick={handlePreviousPage}
                      disabled={currentPage === 1}
                    >
                      Previous
                    </button>
                    <span className="align-self-center">
                      Page {currentPage} of {totalPages}
                    </span>
                    <button
                      className="btn btn-primary ms-2"
                      onClick={handleNextPage}
                      disabled={currentPage === totalPages}
                    >
                      Next
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default User;
