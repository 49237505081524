import React, { useEffect, useState } from "react";
import axios from "axios";
import { useDispatch } from "react-redux";
import {
  addConfirmationCode,
  removeConfirmationCode,
} from "../utils/ConfirmationSlice";
import { useNavigate } from "react-router-dom";

const Progress = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [progress, setProgress] = useState([]);
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}progress`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        setProgress(response?.data);
      })
      .catch((error) => {});

    dispatch(removeConfirmationCode());
  }, []);

  const handleChange = (confirmation_code) => {
    dispatch(addConfirmationCode(confirmation_code));
    navigate("/");
  };
  return (
    <div>
      <div className="container mt-3">
        <h2>Progress</h2>
        <div className="card bg-light">
          <div className="card-body">
            <div className="container mt-3">
              <div className="card bg-light">
                <div className="card-body overflow-auto">
                  <table className="table table-striped overflow-auto">
                    <thead>
                      <tr>
                        <th>Confirmation Code</th>
                        <th>Date of Boarding</th>
                        <th>Start Date of Travel</th>
                        <th>End Date of Travel</th>

                        <th>Flight Code</th>
                        <th>From</th>
                        <th>To</th>
                      </tr>
                    </thead>
                    <tbody>
                      {progress?.map((item, index) => (
                        <tr key={index}>
                          <td
                            onClick={() =>
                              handleChange(item?.confirmation_code)
                            }
                            style={{
                              cursor: "pointer",
                              color: "blue",
                              fontWeight: "bold",
                              textDecoration: "underline",
                            }}
                          >
                            {item?.confirmation_code}
                          </td>
                          <td>{item.date_of_travel}</td>
                          <td>{item?.start_date_of_travel}</td>
                          <td>{item?.end_date_of_travel}</td>
                          <td>{item.flight_code}</td>
                          <td>
                            ({item.from_airport_iata_code}){" "}
                            {item.from_airport_name}, {item.from_city_name}
                          </td>
                          <td>
                            ({item.to_airport_iata_code}) {item.to_airport_name}
                            , {item.to_city_name}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Progress;
