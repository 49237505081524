import React, { useState, useEffect } from "react";
import axios from "axios";
import { Table, Pagination, Modal, Button, Spinner } from "react-bootstrap";

const AreaOfOffering = () => {
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage] = useState(15);
  const [areaOfOfferingCode, setAreaOfOfferingCode] = useState("");
  const [description, setDescription] = useState("");
  const [active, setActive] = useState(false);
  const [checkUpdate, setCheckUpdate] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = data.slice(indexOfFirstRow, indexOfLastRow);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => {
    setAreaOfOfferingCode("");
    setDescription("");
    setActive(false);
    setCheckUpdate(null);
    setShowModal(false);
  };

  useEffect(() => {
    setIsLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}area_of_offering`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        setData(response.data);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  }, [showModal]);

  const handleAddOrUpdate = () => {
    if (!areaOfOfferingCode || !description) {
      alert("Please fill all the fields");
      return;
    }

    const formData = {
      area_of_offering_code: areaOfOfferingCode,
      description,
      active: active ? "1" : "0",
      priority: "99",
    };

    const url = checkUpdate
      ? `${process.env.REACT_APP_API_URL}area_of_offering/${checkUpdate}`
      : `${process.env.REACT_APP_API_URL}area_of_offering`;

    const method = checkUpdate ? axios.post : axios.post;

    method(url, formData, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((response) => {
        setData((prevData) => [...prevData, response.data]);
        handleCloseModal();
      })
      .catch((error) => {
        alert("An error occurred");
      });
  };

  const handleUpdate = (id) => {
    handleShowModal();
    setCheckUpdate(id);
    axios
      .get(`${process.env.REACT_APP_API_URL}area_of_offering/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        const offering = response.data[0];
        setAreaOfOfferingCode(offering.area_of_offering_code);
        setDescription(offering.description);
        setActive(offering.active);
      })
      .catch((error) => {
        alert("An error occurred while fetching the record");
      });
  };

  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(data.length / rowsPerPage); i++) {
    pageNumbers.push(i);
  }

  return (
    <div>
      <div>
        <div className="d-flex justify-content-between align-items-center mb-3">
          <h1>Area of Offering</h1>
          <button className="btn btn-primary m-2" onClick={handleShowModal}>
            ADD
          </button>
        </div>

        {isLoading ? (
          <div className="text-center">
            <Spinner animation="border text-primary" />
          </div>
        ) : (
          <div>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>SNo.</th>
                  <th>Area of Offering Code</th>
                  <th>Description</th>
                  <th>Active</th>
                </tr>
              </thead>
              <tbody>
                {currentRows.map((row, index) => (
                  <tr key={row.area_of_offering_id}>
                    <td>{indexOfFirstRow + index + 1}</td>
                    <td
                      style={{
                        cursor: "pointer",
                        color: "blue",
                        fontWeight: "bold",
                        textDecoration: "underline",
                      }}
                      onClick={() => handleUpdate(row.area_of_offering_id)}
                    >
                      {row.area_of_offering_code}
                    </td>
                    <td>{row.description}</td>
                    <td>{row.active ? "Yes" : "No"}</td>
                  </tr>
                ))}
              </tbody>
            </Table>

            <div className="d-flex justify-content-center">
              <Pagination>
                <Pagination.Prev
                  disabled={currentPage === 1}
                  onClick={() => paginate(currentPage - 1)}
                >
                  {" "}
                  Previous
                </Pagination.Prev>
                {pageNumbers.map((number) => (
                  <Pagination.Item
                    key={number}
                    active={number === currentPage}
                    onClick={() => paginate(number)}
                  >
                    {number}
                  </Pagination.Item>
                ))}
                <Pagination.Next
                  disabled={currentPage === pageNumbers.length}
                  onClick={() => paginate(currentPage + 1)}
                >
                  Next
                </Pagination.Next>
              </Pagination>
            </div>
          </div>
        )}

        <Modal show={showModal} onHide={handleCloseModal}>
          <Modal.Header closeButton>
            <Modal.Title>
              {checkUpdate ? "Update Area of Offering" : "Add Area of Offering"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form>
              <div className="form-group">
                <label>Area of Offering Code</label>
                <input
                  type="text"
                  className="form-control"
                  value={areaOfOfferingCode}
                  onChange={(e) => setAreaOfOfferingCode(e.target.value)}
                  placeholder="Enter Area of Offering Code"
                  required
                />
              </div>
              <div className="form-group">
                <label>Description</label>
                <input
                  type="text"
                  className="form-control"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  placeholder="Enter Description"
                  required
                />
              </div>
              <div className="form-group">
                <label>
                  <input
                    type="checkbox"
                    checked={active}
                    onChange={(e) => setActive(e.target.checked)}
                  />{" "}
                  Active
                </label>
              </div>
            </form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseModal}>
              Close
            </Button>
            <Button variant="primary" onClick={handleAddOrUpdate}>
              Save Changes
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
};

export default AreaOfOffering;
