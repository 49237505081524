import React, { useState, useEffect } from "react";
import axios from "axios";
import { Modal, Button } from "react-bootstrap";

const CityManager = () => {
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedState, setSelectedState] = useState(null);

  const [cityName, setCityName] = useState("");
  const [cityId, setCityId] = useState(null);
  const [cityImageUrl, setCityImageUrl] = useState("");
  const [modalCountry, setModalCountry] = useState(null);
  const [modalStates, setModalStates] = useState([]);
  const [modalState, setModalState] = useState(null);

  const [showModal, setShowModal] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [loading, setLoading] = useState(false);

  // Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 8;

  const indexOfFirstItem = (currentPage - 1) * itemsPerPage;
  const indexOfLastItem = currentPage * itemsPerPage;
  const currentItems = cities.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(cities.length / itemsPerPage);

  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}country`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        setCountries(response.data.data || []);
      } catch (error) {
        console.error("Error fetching countries:", error);
      }
    };
    fetchCountries();
  }, []);

  useEffect(() => {
    if (!selectedCountry) return;

    const fetchStates = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}state/${selectedCountry}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        setStates(response.data.data || []);
      } catch (error) {
        console.error("Error fetching states:", error);
      }
    };
    fetchStates();
  }, [selectedCountry]);

  useEffect(() => {
    if (!selectedState) return;

    const fetchCities = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}subcity/${selectedState}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        setCities(response.data.data || []);
      } catch (error) {
        console.error("Error fetching cities:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchCities();
  }, [selectedState]);

  const resetModal = () => {
    setCityName("");
    setCityId(null);
    setModalCountry(null);
    setModalStates([]);
    setModalState(null);
    setEditMode(false);
    setShowModal(false);
  };

  const handleAddCity = async () => {
    if (!cityName || !modalState) {
      alert("Please fill all required fields!");
      return;
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}city`,
        {
          city_name: cityName,
          state_id: modalState,
          city_image_url: cityImageUrl,
        },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      alert(response.data.message || "City added successfully!");
      resetModal();
    } catch (error) {
      console.error("Error adding city:", error);
      alert("Error adding city. Please try again.");
    }
  };

  const handleEditCity = async (city) => {
    setEditMode(true);
    setCityId(city.city_id);
    setCityName(city.city_name);
    setCityImageUrl(city.city_image_url);
    setModalCountry(selectedCountry);
    setModalState(selectedState);

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}state/${selectedCountry}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      setModalStates(response.data.data || []);
    } catch (error) {
      console.error("Error fetching states for modal:", error);
    }

    setShowModal(true);
  };

  const handleUpdateCity = async () => {
    if (!cityName || !modalState) {
      alert("Please fill all required fields!");
      return;
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}city/${cityId}`,
        {
          city_id: cityId,
          city_name: cityName,
          state_id: modalState,
          city_image_url: cityImageUrl,
        },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      alert(response.data.message || "City updated successfully!");
      resetModal();
    } catch (error) {
      console.error("Error updating city:", error);
      alert("Error updating city. Please try again.");
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage((prev) => prev + 1);
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) setCurrentPage((prev) => prev - 1);
  };

  const setStatesModalss = async (id) => {
    setModalCountry(id);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}state/${id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      setModalStates(response.data.data || []);
    } catch (error) {
      console.error("Error fetching states for modal:", error);
      setModalStates([]);
    }
  };

  return (
    <div className="container mt-3">
      <div className="d-flex justify-content-between align-items-center mb-3">
        <h1>Cities</h1>
        <button className="btn btn-primary" onClick={() => setShowModal(true)}>
          ADD
        </button>
      </div>
      <div className="card bg-light">
        <div className="card-body">
          <div className="mb-3">
            <label htmlFor="countrySelect" className="form-label mt-4">
              Select Country
            </label>
            <select
              id="countrySelect"
              className="form-select"
              value={selectedCountry || ""}
              onChange={(e) => setSelectedCountry(e.target.value)}
            >
              <option value="">Select a country</option>
              {countries.map((country) => (
                <option key={country.country_id} value={country.country_id}>
                  {country.country_name}
                </option>
              ))}
            </select>
          </div>
          <div className="mb-3">
            <label htmlFor="stateSelect" className="form-label">
              Select State
            </label>
            <select
              id="stateSelect"
              className="form-select"
              value={selectedState || ""}
              onChange={(e) => setSelectedState(e.target.value)}
            >
              <option value="">Select a state</option>
              {states.map((state) => (
                <option key={state.state_id} value={state.state_id}>
                  {state.state_name}
                </option>
              ))}
            </select>
          </div>
          {loading ? (
            <div className="d-flex justify-content-center align-items-center">
              <div className="spinner-border text-primary" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          ) : cities.length === 0 ? (
            <div className="text-center mt-3">
              <h5>No Cities Found</h5>
            </div>
          ) : (
            <div className="mt-3">
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th>SNo.</th>
                    <th>City Name</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {currentItems.map((city, index) => (
                    <tr key={city.city_id}>
                      <td>{indexOfFirstItem + index + 1}</td>
                      <td>{city.city_name}</td>
                      <td>
                        <Button
                          variant="primary"
                          onClick={() => handleEditCity(city)}
                        >
                          Edit
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className="d-flex justify-content-center mt-3">
                <button
                  className="btn btn-primary me-2"
                  onClick={handlePreviousPage}
                  disabled={currentPage === 1}
                >
                  Previous
                </button>
                <span className="align-self-center">
                  Page {currentPage} of {totalPages}
                </span>
                <button
                  className="btn btn-primary ms-2"
                  onClick={handleNextPage}
                  disabled={currentPage === totalPages}
                >
                  Next
                </button>
              </div>
            </div>
          )}
        </div>
      </div>

      <Modal show={showModal} onHide={resetModal}>
        <Modal.Header closeButton>
          <Modal.Title>{editMode ? "Edit City" : "Add City"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
            <div className="mb-3">
              <label htmlFor="modalCountry" className="form-label">
                Country
              </label>
              <select
                id="modalCountry"
                className="form-select"
                value={modalCountry || ""}
                onChange={async (e) => setStatesModalss(e.target.value)}
              >
                <option value="">Select a country</option>
                {countries.map((country) => (
                  <option key={country.country_id} value={country.country_id}>
                    {country.country_name}
                  </option>
                ))}
              </select>
            </div>
            <div className="mb-3">
              <label htmlFor="modalState" className="form-label">
                State
              </label>
              <select
                id="modalState"
                className="form-select"
                value={modalState || ""}
                onChange={(e) => setModalState(e.target.value)}
              >
                <option value="">Select a state</option>
                {modalStates.map((state) => (
                  <option key={state.state_id} value={state.state_id}>
                    {state.state_name}
                  </option>
                ))}
              </select>
            </div>
            <div className="mb-3">
              <label htmlFor="cityName" className="form-label">
                City Name
              </label>
              <input
                type="text"
                id="cityName"
                className="form-control"
                value={cityName}
                onChange={(e) => setCityName(e.target.value)}
                required
              />
            </div>
            <div className="mb-3">
              <label htmlFor="cityImageUrl" className="form-label">
                City Image URL
              </label>
              <input
                type="text"
                id="cityImageUrl"
                className="form-control"
                value={cityImageUrl}
                onChange={(e) => setCityImageUrl(e.target.value)}
              />
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={resetModal}>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={editMode ? handleUpdateCity : handleAddCity}
          >
            {editMode ? "Update City" : "Add City"}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default CityManager;
