import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import "bootstrap/dist/css/bootstrap.min.css";
// import "./index.css"; // If you have custom CSS
import { Link } from "react-router-dom";
import "@fortawesome/fontawesome-svg-core/styles.css"; // For Font Awesome

const Sidebar = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  return (
    <nav id="sidebar" className={sidebarOpen ? "active" : ""}>
      <div className="p-4">
        <button
          type="button"
          id="sidebarCollapse"
          className="btn btn-dark d-block d-md-none"
          onClick={toggleSidebar}
        >
          <FontAwesomeIcon icon={faBars} />
        </button>
        <h5 className="mb-4"></h5>
        <ul className="list-unstyled components">
          <li>
            CRM
            <ul>
              <li>
                <Link to="/" className="text-black">
                  Search by confirmation code
                </Link>
              </li>
              <li>
                <Link to="/phone-number" className="text-black">
                  Search by phone number
                </Link>
              </li>
              <li>
                <Link to="/search-city" className="text-black ">
                  Search Travel Plans
                </Link>
              </li>
              <li>
                <Link to="/progress" className="text-black">
                  Travels In-Progress
                </Link>
              </li>

              <li>
                <Link to="/ticket/uploaded" className="text-black">
                  Tickets
                </Link>
              </li>
              <li>
                <Link to="/companion" className="text-black">
                  Paid Companions
                </Link>
              </li>
              <li>
                <Link to="/recent-booking" className="text-black">
                  Recent Bookings
                </Link>
              </li>
              <li>
                <Link to="/recent-feedbck" className="text-black ">
                  Recent Feedbacks
                </Link>
              </li>
              <li>
                <Link to="/recent-request" className="text-black ">
                  Recent Requests
                </Link>
              </li>

              <li>
                <Link to="/user" className="text-black ">
                  Users
                </Link>
              </li>
              <li>
                <Link to="/flight-enable" className="text-black">
                  Review Flights
                </Link>
              </li>
            </ul>
          </li>

          <li>
            Report
            <ul>
              <li>
                <Link to="/chart" className="text-black ">
                  Charts
                </Link>
              </li>
            </ul>
          </li>

          <li>
            Admin
            <ul>
              <li>
                <Link to="/city" className="text-black">
                  City
                </Link>
              </li>
              <li>
                <Link to="/airport" className="text-black">
                  Airport
                </Link>
              </li>
              <li>
                <Link to="/airline" className="text-black">
                  Airline
                </Link>
              </li>
              <li>
                <Link to="/flight" className="text-black ">
                  Flight
                </Link>
              </li>
              <li>
                <Link to="/country" className="text-black ">
                  Country
                </Link>
              </li>
              <li>
                <Link to="/state" className="text-black ">
                  State
                </Link>
              </li>
              <li>
                <Link to="/language" className="text-black ">
                  Language
                </Link>
              </li>
              <li>
                <Link to="/age" className="text-black ">
                  Age Range
                </Link>
              </li>
              <li>
                <Link to="/feedback" className="text-black ">
                  Feedback Subject
                </Link>
              </li>

              <li>
                <Link to="/area-of-service" className="text-black ">
                  Area Of Offering
                </Link>
              </li>
              <li>
                <Link to="/service-type" className="text-black ">
                  Offering type
                </Link>
              </li>
              <li>
                <Link to="/service" className="text-black ">
                  Offering
                </Link>
              </li>
              <li>
                <Link to="/transit_step" className="text-black ">
                  Transit Step
                </Link>
              </li>
              <li>
                <Link to="/airport_source_transit_step" className="text-black ">
                  Airport Source Transit Step
                </Link>
              </li>
              <li>
                <Link
                  to="/airport_destination_transit_step"
                  className="text-black "
                >
                  Airport Destination Transit Step
                </Link>
              </li>
              <li>
                <Link to="/transit-pricing" className="text-black ">
                  Transit Pricing
                </Link>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default Sidebar;
