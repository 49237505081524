import React, { useState, useEffect } from "react";
import axios from "axios";
import { Table, Pagination, Modal, Button } from "react-bootstrap"; // Import Modal and Button from react-bootstrap

const TransitSteps = () => {
  const [transitStepData, settransitStepData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage] = useState(10);
  const [airlineName, setAirlineName] = useState("");
  const [airlineIataCode, setAirlineIataCode] = useState("");
  const [checkUpdate, setCheckUpdate] = useState(null);
  const [transitStepCode, settransitStepCode] = useState("");
  const [domestic, setDomestic] = useState(); // Set default value for domestic and international
  const [international, setInternational] = useState(); // Set default value for domestic and international
  const [countries, setCountries] = useState([]);
  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = transitStepData.slice(indexOfFirstRow, indexOfLastRow);
  const [active, setActive] = useState(false);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => {
    setAirlineName("");
    setAirlineIataCode("");
    settransitStepCode("");
    setDomestic(false);
    setInternational(false);
    setActive(false);
    setCheckUpdate(null);

    setShowModal(false);
  };

  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}transit_steps`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        settransitStepData(response.data);
        //(response.data.data);
      })
      .catch((error) => {
        //(error);
      });
    axios
      .get(`${process.env.REACT_APP_API_URL}country`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        setCountries(response.data.data);
      })
      .catch((error) => {
        //(error);
      });
  }, [showModal]);

  const handleAddTransitCode = () => {
    if (!transitStepCode) {
      alert("Please fill all the fields");
      return;
    }

    const formData = {
      transit_code: transitStepCode,

      active: active ? 1 : 0,
    };

    if (checkUpdate !== null) {
      //("update");
      axios
        .post(
          `${process.env.REACT_APP_API_URL}transit_steps/${checkUpdate}`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        )
        .then((response) => {
          settransitStepData([...transitStepData, response.data.data]);

          settransitStepCode("");

          setActive(false);
          setShowModal(false);
          setCheckUpdate(null);
          //(formData);
        })
        .catch((error) => {
          //(error);
        });
    } else {
      axios
        .post(`${process.env.REACT_APP_API_URL}transit_steps`, formData, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((response) => {
          settransitStepData([...transitStepData, response.data.data]);

          settransitStepCode("");

          setActive(false);
          setCheckUpdate(null);
          setShowModal(false);
          //(formData);
        })
        .catch((error) => {
          //(error);
        });
    }
  };
  const handleUpdateAirline = (e) => {
    handleShowModal();
    setCheckUpdate(e);

    axios
      .get(`${process.env.REACT_APP_API_URL}transit_steps/${e}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        settransitStepCode(response.data[0].transit_code);

        setActive(response.data[0].active);
      })
      .catch((error) => {});
  };

  const transitSteps = [
    "YET_TO_TRAVEL",
    "AT_MEETING_POINT",
    "MET_THE_PROVIDER",
    "INSIDE_SOURCE_AIRPORT_TERMINAL",
    "DROPPED_LUGGAGE",
    "COLLECTED_BOARDING_PASS",
    "COMPLETED_SOURCE_IMMIGRATION",
    "COMPLETED_SOURCE_SECURITY",
    "COMPLETED_SOURCE_ADDITIONAL_SECURITY",
    "AT_BOARDING_GATE",
    "BOARDED",
    "IN_FLIGHT",
    "DEBOARDED",
    "AT_DESTINATION_IMMIGRATION",
    "IMMIGRATION_CLEARED",
    "AT_LUGGAGE_BELT",
    "LUGGAGE_COLLECTED",
    "EXITING_AIRPORT",
  ];

  return (
    <div>
      <div className="d-flex justify-content-between align-items-center mb-3">
        <h1>Transit Step</h1>
        <button
          className="btn btn-primary "
          style={{ marginRight: "1rem" }}
          onClick={handleShowModal}
        >
          ADD
        </button>
      </div>

      <Table striped bordered hover>
        <thead>
          <tr>
            <th>SNo.</th>
            <th>Transit Code</th>
            <th>Active</th>
          </tr>
        </thead>
        <tbody>
          {currentRows.map((transit, index) => (
            <tr key={transit?.transit_step_id}>
              <td>{indexOfFirstRow + index + 1}</td>
              <td
                onClick={() => handleUpdateAirline(transit?.transit_step_id)}
                style={{
                  cursor: "pointer",
                  color: "blue",
                  fontWeight: "bold",
                  textDecoration: "underline",
                }}
              >
                {transit?.transit_code}
              </td>
              <td>{transit?.active ? "Yes" : "No"}</td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Pagination>
        {Array(Math.ceil(transitStepData.length / rowsPerPage))
          .fill()
          .map((_, index) => (
            <Pagination.Item
              key={index}
              active={index + 1 === currentPage}
              onClick={() => paginate(index + 1)}
            >
              {index + 1}
            </Pagination.Item>
          ))}
      </Pagination>

      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Add Transit Step</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form id="addAirlineForm">
            <div className="form-group">
              <label htmlFor="transitStepCode">Transit Code</label>
              {/* <select
                className="form-control"
                onChange={(e) => settransitStepCode(e.target.value)}
                name="transitStepCode"
                value={transitStepCode}
                required
              >
                <option value="">Select a transit code</option>
                {transitSteps.map((step) => (
                  <option key={step} value={step}>
                    {step}
                  </option>
                ))}
              </select> */}

              <input
                type="text"
                className="form-control"
                onChange={(e) => settransitStepCode(e.target.value)}
                name="areaOfService"
                placeholder="Enter Transit Code"
                value={transitStepCode}
                required
              />
            </div>

            <div className="form-group">
              <div style={{ display: "flex" }}>
                <label
                  htmlFor="active"
                  style={{
                    marginRight: "1rem",
                  }}
                >
                  Active
                </label>
                <div>
                  <input
                    type="checkbox"
                    onChange={(e) => setActive(e.target.checked)}
                    name="active"
                    checked={active}
                  />
                </div>
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
          <Button variant="primary" onClick={handleAddTransitCode}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default TransitSteps;
